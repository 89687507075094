import React from "react";
import geezy from "../../assests/geezy x.svg";
import {  useNavigate } from "react-router-dom";
import "../styles/header.css";

const PrivacyHeader = () => {
  const navigate = useNavigate();

  return (
    <header className={"main-header"}>
      <nav class="navbar navbar-inverse navbar-fixed-top geezy-header">
        <div
          class="container-fluid header-section"
          style={{
            padding: `10px`,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div class="navbar-header" onClick={() => {
                      navigate("/");
                      window.scrollTo(0, 0);
                    }}>
            <img
              src={geezy}
              alt=""
              style={{ marginTop: "5px", cursor: "pointer" }}
            />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default PrivacyHeader;
