import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Suggested from "./suggested";
import Brands from "../../components/BrandsTrust/Brands";
import BlogContent from "./blogContent";

const Blog = () => {
  return (
    <>
      <Header />
      <BlogContent />
      <Suggested />
      <Brands />
      <Footer />
    </>
  );
};

export default Blog;
