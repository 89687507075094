import { Grid } from "@mui/material";
import React from "react";
import "../styles/service.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Service11 from "../../assests/Service11.png";
import Service22 from "../../assests/Service22.png";
import Service33 from "../../assests/Service33.png";
import Service44 from "../../assests/Service44.png";

const Service = () => {
  return (
    <>
      <h1 className="service-heading" style={{ paddingTop: "120px" }}>
        Additional Services
      </h1>
      <Grid container className="service-container2">
        <Grid item lg={12} style={{ display: "flex" }}>
          <Card
            sx={{
              marginRight: "15px",
              maxWidth: 311,
              borderRadius: "30px",
              background: "#F5F5F5",
            }}
          >
            <CardMedia
              sx={{ height: "220px" }}
              image={Service11}
              style={{ borderRadius: "25px" }}
            />
            <CardContent sx={{ height: "150px", background: "#F5F5F5" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="ser-head"
                style={{
                  fontFamily: "Amaranth",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "17px",
                  color: "#000000",
                  padding: "12px 12px 2px 0px",
                }}
              >
                Product Sampling
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="sec-para"
                style={{
                  width: "272px",
                  fontFamily: "Amaranth",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000000",
                  padding: "12px 12px 2px 0px",
                }}
              >
                Collect customer data and boost awareness & engagement through
                our product sampling services.
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              marginRight: "15px",
              maxWidth: 311,
              borderRadius: "30px",
              background: "#F5F5F5",
            }}
          >
            <CardMedia
              sx={{ height: "220px" }}
              image={Service22}
              style={{ borderRadius: "30px" }}
            />
            <CardContent sx={{ height: "150px", background: "#F5F5F5" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="ser-head"
                style={{
                  fontFamily: "Amaranth",
                  fontWeight: 400,
                  fontSize: "17px",
                  lineHeight: "18px",
                  color: "#000000",
                  padding: "12px 12px 2px 0px",
                }}
              >
                Branded Food Delivery Bag
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="sec-para"
                style={{
                  width: "274px",
                  fontFamily: "Amaranth",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000000",
                  padding: "12px 12px 2px 0px",
                }}
              >
                Distribute samples in branded food delivery bags for better
                customer engagement.
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              marginRight: "15px",
              maxWidth: 311,
              borderRadius: "30px",
              background: "#F5F5F5",
            }}
          >
            <CardMedia
              sx={{ height: "220px" }}
              image={Service33}
              style={{ borderRadius: "30px" }}
            />
            <CardContent sx={{ height: "150px", background: "#F5F5F5" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="ser-head"
                style={{
                  fontFamily: "Amaranth",
                  fontWeight: 400,
                  fontSize: "17px",
                  lineHeight: "18px",
                  color: "#000000",
                  padding: "12px 12px 2px 0px",
                }}
              >
                Insert Placement
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="sec-para"
                style={{
                  fontFamily: "Amaranth",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000000",
                  padding: "12px 12px 2px 0px",
                }}
              >
                Place promotional items like brands coupons or brochures  to target the right audiences efficiently .
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              marginRight: "15px",
              maxWidth: 311,
              borderRadius: "30px",
              background: "black",
            }}
          >
            <CardMedia
              sx={{ height: "220px" }}
              image={Service44}
              style={{ borderRadius: "30px" }}
            />
            <CardContent sx={{ height: "150px", background: "#F5F5F5" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="ser-head"
                style={{
                  fontFamily: "Amaranth",
                  fontWeight: 400,
                  fontSize: "17px",
                  lineHeight: "18px",
                  color: "#000000",
                  padding: "12px 12px 2px 0px",
                }}
              >
                In App Promotions for food delivery
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="sec-para"
                style={{
                  width: "273px",
                  fontFamily: "Amaranth",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "rgb(0, 0, 0)",
                  padding: "12px 12px 2px 0px",
                }}
              >
                Effective use of in-app promotions to keep consumers up to date
                on upgrades and availabilities
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default Service;
