import React from "react";
import "../../components/styles/gallery.css";
import { Grid } from "@mui/material";
import Gal9 from "../../assests/Gallery/G9.png";
import Gal10 from "../../assests/Gallery/G10.png";
import Gal11 from "../../assests/Gallery/G11.png";
import Gal12 from "../../assests/Gallery/G12.png";

const FitnessGallery = () => {
    return (
        <>
            <h1 className="gal-heading">Gallery</h1>
            <Grid container style={{ padding: ' 0px 67px 0px',marginBottom:'125px' }}>
                <Grid item lg={12} className="gal-12-div">
                    <div className="gal-div">
                        <img src={Gal9} alt="" />
                    </div>
                    <div className="gal-div1">
                    <img src={Gal10} alt="" />
                    </div>
                    <div className="gal-div2">
                    <img src={Gal11} alt="" />
                    </div>
                    <div className="gal-div1">
                    <img src={Gal12} alt="" />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
export default FitnessGallery