import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../../components/styles/caseStudies.css";
import { useNavigate } from "react-router-dom";
import { CustomPagination } from "../../components/CustomPagination";
import BlogCard from "../../components/BlogCard/blogCard";
import { services } from "../../service/ServiceConstants";

const Blogs = () => {
  const navigate = useNavigate();
  const [totalNoOfPages, setTotalNoOfPages] = useState(1);
  const [blogList, setBlogList] = useState([]);

  const [filters, setFilters] = useState({
    page: 1,
    query: "Blog",
  });

  const handleChange = (event, value) => {
    setFilters({ ...filters, page: value });
  };

  // const blogList = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 11, 1];

  const fetchBlogsList = async () => {
    // setLoading(true);
    const response = await services.fetchBlogsList(filters);
    if (response?.data?.contents?.length > 0) {
      setBlogList(response?.data?.contents);
      setTotalNoOfPages(response?.data?.totalPages);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogsList();
  }, [filters.page]);

  return (
    <Grid container style={{ width: "95%" }}>
      <h2 className="caseHeading">This Is How We Do It</h2>
      <Grid container>
        <Grid
          item
          lg={12}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginLeft: "80px",
          }}
        >
          {blogList?.length > 0 &&
            blogList
              ?.slice(0, 9)
              ?.map(({ body1, desc, images, title, createdAt, _id }) => {
                return (
                  <Grid item xs={4} style={{ paddingBottom: "40px" }}>
                    <BlogCard
                      body={body1}
                      desc={desc}
                      images={images}
                      title={title}
                      date={createdAt}
                      blogId={_id}
                    />
                  </Grid>
                );
              })}
        </Grid>

        <Grid item lg={12} className="case2" style={{ padding: "50px 0" }}>
          {blogList?.length > 9 && totalNoOfPages > 1 ? (
            <CustomPagination
              count={totalNoOfPages}
              page={filters?.page}
              handleChange={handleChange}
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Blogs;
