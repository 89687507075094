import axios from "axios";
import { BASE_URL } from "./GlobalConstant";
import {
  errorHandler,
  requestHandler,
  successHandler,
} from "./NetworkInterceptors";

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

// ADD INTERCEPTORS

axiosInstance.interceptors.request.use((request) => requestHandler(request));

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
