import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cococola from "../../assests/cococola.png";
import Lipton from "../../assests/lipton.png";
import Gin from "../../assests/gin.png";
import "../styles/caseStudies.css";
import { Link, useNavigate } from "react-router-dom";
import { services } from "../../service/ServiceConstants";
import BlogCard from "../BlogCard/blogCard";

const CaseArticles = ({ CaseStudies }) => {
  const [blogList, setBlogList] = useState();

  const [filters, setFilters] = useState({
    page: 1,
    query: CaseStudies,
  });
  const [loading, setLoading] = useState(false);

  const fetchBlogsList = async () => {
    setLoading(true);
    const response = await services.fetchBlogsList(filters);
    if (response?.data?.contents?.length > 0) {
      setBlogList(response?.data?.contents);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogsList();
  }, [filters.page]);
  const navigate = useNavigate();
  return (
    <div id="caseStudies">
      <h2 className="caseHeading">Case Studies</h2>
      <Grid container>
        <Grid item lg={12} className="main-case">
          {blogList?.length > 0 &&
            blogList
              ?.slice(0, 3)
              ?.map(({ body1, desc, images, title, createdAt, _id }) => {
                return (
                  <Grid item xs={4} style={{ paddingBottom: "40px" }}>
                    <BlogCard
                      body={body1}
                      desc={desc}
                      images={images}
                      title={title}
                      date={createdAt}
                      blogId={_id}
                      suggested={true}
                      CaseStudies={CaseStudies}
                    />
                  </Grid>
                );
              })}
        </Grid>
        {blogList?.length > 0 && (
          <Grid item lg={12} className="case2">
            <div
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/in-case-studies");
              }}
              className="view-all"
              style={{
                marginBottom: CaseStudies ? "80px" : "150px",
                marginTop: "10px",
              }}
            >
              View All
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CaseArticles;
