import React from "react";
import "../../components/styles/gallery.css";
import { Grid } from "@mui/material";
import Gal17 from "../../assests/Gallery/G17.png";
import Gal18 from "../../assests/Gallery/G18.png";
import Gal19 from "../../assests/Gallery/G19.png";
import Gal20 from "../../assests/Gallery/G20.png";

const FoodGallery = () => {
    return (
        <>
            <h1 className="gal-heading">Gallery</h1>
            <Grid container style={{ padding: ' 0px 67px 0px',marginBottom:'125px' }}>
                <Grid item lg={12} className="gal-12-div">
                    <div className="gal-div">
                        <img src={Gal17} alt="" />
                    </div>
                    <div className="gal-div1">
                    <img src={Gal18} alt="" />
                    </div>
                    <div className="gal-div2">
                    <img src={Gal19} alt="" />
                    </div>
                    <div className="gal-div1">
                    <img src={Gal20} alt="" />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
export default FoodGallery