import React from "react";
import "../styles/samplechannel.css";
import { Grid } from "@mui/material";
import SamplingCards from "./SamplingCards";
const SampleChannel = () => {
  return (
    <>
      <Grid container style={{ paddingTop: "120px", paddingBottom: "60px" }}>
        <Grid item lg={6} className="pad">
          <h2
            className="sample-heading"
            style={{
              marginBottom: "0px",
              fontSize: "64px",
              width: "800px",
            }}
          >
            Our Sampling Channels
          </h2>
          <p className="sample-para">
            Geezy X has the largest portfolio of sampling channels available
            globally. These channels enable brands and agencies to create
            sampling campaigns that engage with a relevant audience, at relevant
            times, and sample at point of use.
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <SamplingCards />
      </Grid>
    </>
  );
};
export default SampleChannel;
