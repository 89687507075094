export const COLORS = {

    bgPrimary: '#F5F5F5',

    bgSecondary: '#800080',

    buttonPrimary: '#800080',

    buttonSecondary: '#800080',

    textPrimary: '#800080',

    textSecondary: '#800080',

    color1: '#800080',

    color2: '#800080',

    color3: 'rgba(134, 56, 229, 0.25)',

    color4: 'rgba(40, 44, 53, 1)',

    color5: '#282C35',

    color6: 'rgba(40, 44, 53, 0.6)',

    color7: 'rgba(134, 56, 229, 0.05)',

    color8: 'rgba(134, 56, 229, 0.09)',

    color9: 'rgba(134, 56, 229, 0.3)',

    color10: 'rgba(40, 44, 53, 0.6)',

    lightGrey: '#F1E7FA',

    darkCharcoal: '#333333',

    Gray88: '#e0e0e0',

    darkGrey: '#9e9e9e',

    White: 'white',

    lighterGrey: '#eeeeee',

    checkIcon: '#08B924',

    watchIcon: '#4C9AFF',

    openIcon: '#FFD35C',

    crossIcon: '#DE350B',

    retryIcon: '#0B1E34',

    bgColor: 'rgba(222, 53, 11, 0.05)',

    purple: '#8638E5',

    black: '#000000',

    disabledSpace: '#B8B8B8',

    skyBlue: '#3FB0F7',

    campaignClosed: '#B248CD',

    placeHolderGrey: '#c0c1c3',

    lightPurple: 'rgba(134, 56, 229, 0.2)',

    labelGrey: 'rgba(9, 30, 66, 0.4)',

    white: '#ffffff',

};