import { useState } from "react";
import { Grid } from "@mui/material";
import Orange from "../../assests/orange.png";
import { useEffect } from "react";

const SamplingCards = () => {
  const [active, setActive] = useState(0);
  const [reverse, setReverse] = useState();
  const [timeout, setTime] = useState(false);
  const [clicked, setClicked] = useState();

  useEffect(() => {
    setTimeout(() => {
      setTime(false);
      setClicked(false);
    }, 60000);
  }, [timeout]);

  useEffect(() => {
    const timer = timeout ? 10000 : 4000;
    if (!timeout) {
      setTimeout(() => {
        if (reverse && active !== 0) {
          setActive(active - 1);
          if (active === 1) {
            setReverse(false);
          }
        } else {
          setActive(active + 1);
          if (active === 4) {
            setReverse(true);
          }
        }
      }, timer);
    }
  }, [active, timeout]);

  return (
    <Grid
      xl={12}
      lg={12}
      container
      className="margins"
      style={{
        paddingLeft: "73px",
        height: "502px",
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
      }}
    >
      <Grid
        item
        onClick={() => {
          if (clicked !== 0) {
            setTime(true);
            setClicked(0);
          }
        }}
        className={`${
          (active === 0 && !timeout) || clicked === 0
            ? "sampling-card-lab3"
            : "sampling-card"
        }`}
        style={{
          marginRight: "15px",
          padding: "30px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        {(active === 0 && !timeout) || clicked === 0 ? (
          <div className="blurred-card">
            <div style={{ fontFamily: "Amaranth", color: "white" }}>
              Food Delivery Sampling
            </div>
            <div
              style={{
                width: "530px",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "21px",
                paddingTop: "20px",
                fontFamily: "Amaranth",
                color: "white",
              }}
            >
              Hand your samples to ideal customers by sampling your products
              with 125k restaurant partners globally. Done through Uber Eats,
              Deliveroo, Doordash and other delivery services.{" "}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "502px",
              fontWeight: 700,
              fontSize: "30px",
              lineHeight: "28px",
              color: "#FFFFFF",
              position: "absolute",
              top: 200,
              left: -190,
              transform: "rotate(-90deg)",
              fontFamily: "Amaranth",
            }}
          >
            Food Delivery Sampling
          </div>
        )}
      </Grid>
      <Grid
        item
        onClick={() => {
          if (clicked !== 1) {
            setTime(true);
            setClicked(1);
          }
        }}
        className={`${
          (active === 1 && !timeout) || clicked === 1
            ? "sampling-card-lab2"
            : "sampling-card"
        }`}
        style={{
          marginRight: "15px",
          padding: "30px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        {(active === 1 && !timeout) || clicked === 1 ? (
          <div className="blurred-card">
            <div style={{ fontFamily: "Amaranth", color: "white" }}>
              Office/ Co-working Sampling
            </div>
            <div
              style={{
                width: "530px",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "21px",
                paddingTop: "20px",
                fontFamily: "Amaranth",
                color: "white",
              }}
            >
              Reach the right consumers in targeted workplace environment to
              create brand awareness. We offer more than 4000+ co-working and
              office spaces to establish a strong brand name.{" "}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "502px",
              fontWeight: 700,
              fontSize: "30px",
              lineHeight: "28px",
              color: "#FFFFFF",
              position: "absolute",
              top: 200,
              left: -190,
              transform: "rotate(-90deg)",
              fontFamily: "Amaranth",
            }}
          >
            Office/ Co-working Sampling
          </div>
        )}
      </Grid>
      <Grid
        onClick={() => {
          if (clicked !== 2) {
            setTime(true);
            setClicked(2);
          }
        }}
        item
        className={`${
          (active === 2 && !timeout) || clicked === 2
            ? "sampling-card-lab"
            : "sampling-card"
        }`}
        style={{
          marginRight: "15px",
          padding: "30px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        {(active === 2 && !timeout) || clicked === 2 ? (
          <div className="blurred-card-fitness">
            <div style={{ fontFamily: "Amaranth" }}>
              {" "}
              Fitness Center Sampling
            </div>
            <div
              style={{
                width: "540px",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "21px",
                paddingTop: "20px",
                fontFamily: "Amaranth",
              }}
            >
              With over 2600+ gyms across the globe, our gym sampling services
              help in targeting health-conscious audience who would love to
              engage with your brand.{" "}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "502px",
              fontWeight: 700,
              fontSize: "30px",
              lineHeight: "28px",
              color: "#FFFFFF",
              position: "absolute",
              top: 200,
              left: -190,
              transform: "rotate(-90deg)",
              fontFamily: "Amaranth",
            }}
          >
            Fitness Center Sampling
          </div>
        )}
      </Grid>
      <Grid
        item
        onClick={() => {
          if (clicked !== 3) {
            setTime(true);
            setClicked(3);
          }
        }}
        className={`${
          (active === 3 && !timeout) || clicked === 3
            ? "sampling-card-lab-airbnb"
            : "sampling-card"
        }`}
        style={{
          marginRight: "15px",
          padding: "30px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        {(active === 3 && !timeout) || clicked === 3 ? (
          <div className="blurred-card-air">
            <div style={{ fontFamily: "Amaranth" }}>Airbnb Sampling</div>
            <div
              style={{
                width: "550px",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "21px",
                paddingTop: "20px",
                fontFamily: "Amaranth",
              }}
            >
              Our 85K+ short-term rental spaces globally bring an array of
              opportunities for brand owners to place their product samples in
              locations that will have maximum customer engagement.{" "}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "502px",
              fontWeight: 700,
              fontSize: "30px",
              lineHeight: "28px",
              color: "#FFFFFF",
              position: "absolute",
              top: 200,
              left: -190,
              transform: "rotate(-90deg)",
              fontFamily: "Amaranth",
            }}
          >
            Airbnb Sampling
          </div>
        )}
      </Grid>
      <Grid
        item
        onClick={() => {
          if (clicked !== 4 || clicked !== 5) {
            setTime(true);
            setClicked(4);
          }
        }}
        className={`${
          ((active === 4 || active === 5 || active === 6) && !timeout) ||
          clicked === 4
            ? "sampling-card-lab-saloon"
            : "sampling-card"
        }`}
        style={{
          marginRight: "15px",
          padding: "30px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        {((active === 4 || active === 5 || active === 6) && !timeout) ||
        clicked === 4 ? (
          <div className="blurred-card">
            <div style={{ fontFamily: "Amaranth", color: "white" }}>
              Restaurants & Cafes Sampling
            </div>
            <div
              style={{
                width: "550px",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "21px",
                paddingTop: "20px",
                fontFamily: "Amaranth",
                color: "white",
              }}
            >
              With over 3500+ hotels across globe, distribute your samples to
              the those who align the best with your requirement and watch your
              sales boost up higher.{" "}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "502px",
              fontWeight: 700,
              fontSize: "30px",
              lineHeight: "28px",
              color: "#FFFFFF",
              position: "absolute",
              top: 200,
              left: -190,
              transform: "rotate(-90deg)",
              fontFamily: "Amaranth",
            }}
          >
            Restaurants & Cafes Sampling
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default SamplingCards;
