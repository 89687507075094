import React from "react";
import "../../components/styles/gallery.css";
import { Grid } from "@mui/material";
import Gal13 from "../../assests/Gallery/G13.png";
import Gal14 from "../../assests/Gallery/G14.png";
import Gal15 from "../../assests/Gallery/G15.png";
import Gal16 from "../../assests/Gallery/G16.png";

const HotelGallery = () => {
    return (
        <>
            <h1 className="gal-heading">Gallery</h1>
            <Grid container style={{ padding: ' 0px 67px 0px',marginBottom:'125px' }}>
                <Grid item lg={12} className="gal-12-div">
                    <div className="gal-div">
                        <img src={Gal13} alt="" />
                    </div>
                    <div className="gal-div1">
                    <img src={Gal14} alt="" />
                    </div>
                    <div className="gal-div2">
                    <img src={Gal15} alt="" />
                    </div>
                    <div className="gal-div1">
                    <img src={Gal16} alt="" />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
export default HotelGallery