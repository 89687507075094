import React from "react";
import Header from "../../components/Header/Header";
import CaseStudies from "../../components/CaseStudies/CaseArticles";
import AboutContent from "./AboutContent";
import AboutBrands from "./AboutBrands";
import Faq from "../../components/FAQ's/Faq";
import Footer from "../../components/Footer/Footer";
import ConsumersInternal from "../../components/Consumers/ConsumersInternal";

const AboutUs = () => {
    return(
        <>
        <Header />
        <AboutContent />
        <CaseStudies />
        <ConsumersInternal />
        <AboutBrands />
        <Footer />
        </>
    )
}
export default AboutUs