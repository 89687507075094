import { Button, Grid } from "@mui/material";
import React from "react";
import "../styles/service.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import lipton from "../../assests/LiptonPepsi.png";
import Pepsi from "../../assests/PepsiRemedey.png";
import Jimbeam from "../../assests/jimbeam.png";
import Whiteclaw from "../../assests/whiteclaw.png";
import Sanitizer from "../../assests/sanitizer.png";

import { useRef } from "react";
import { useEffect } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "500px",
        right: "50rem",
        background: "green",
        borderRadius: "20px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "grey",
        position: "absolute",
        left: "2px",
        top: "500px",
        borderRadius: "20px",
      }}
      onClick={onClick}
    />
  );
}

const CampScroll = () => {
  const sliderRef = useRef(null);
  useEffect(() => {
    console.log(sliderRef);
  }, []);

  const sliderSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />
  };
  return (
    <>
      <Grid container className="service-container">
        <Grid item lg={12}>
          <Slider ref={sliderRef} {...sliderSettings}>
            <div>
              <Card
                sx={{
                  marginRight: "12px",
                  maxWidth: 300,
                  borderRadius: "30px",
                  background: "#D9D9D9",
                }}
              >
                <CardMedia
                  sx={{ height: "220px" }}
                  image={lipton}
                  style={{ borderRadius: "25px" }}
                />
                <CardContent sx={{ height: "180px", background: "#D9D9D9" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="ser-head"
                    style={{
                      fontFamily: "Amaranth",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "18px",
                      color: "#000000",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    Lipton Ice Tea
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "18px",
                      color: "#000000",
                      height: "80px",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    “Customers call me asking when I will get more Lipton, so
                    keep them coming!”
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#000000",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    Erbay,
                  </Typography>
                  <Typography
                    vvariant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#000000",
                    }}
                  >
                    Restaurant Partner
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card
                sx={{
                  marginRight: "12px",
                  maxWidth: 300,
                  borderRadius: "30px",
                  background: "#D9D9D9",
                }}
              >
                <CardMedia
                  sx={{ height: "220px" }}
                  image={Jimbeam}
                  style={{ borderRadius: "25px" }}
                />
                <CardContent sx={{ height: "180px", background: "#D9D9D9" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="ser-head"
                    style={{
                      fontFamily: "Amaranth",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "18px",
                      color: "#000000",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    Jim Beam
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "18px",
                      color: "#000000",
                      height: "80px",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    “Wish the campaign lasted a bit longer, as all our customers
                    were loving it.”
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#000000",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    Restaurant Partner,
                  </Typography>
                  <Typography
                    vvariant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#000000",
                    }}
                  >
                    Brisbane
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card
                sx={{
                  marginRight: "12px",
                  maxWidth: 300,
                  borderRadius: "30px",
                  background: "#D9D9D9",
                }}
              >
                <CardMedia
                  sx={{ height: "220px" }}
                  image={Pepsi}
                  style={{ borderRadius: "25px" }}
                />
                <CardContent sx={{ height: "180px", background: "#D9D9D9" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="ser-head"
                    style={{
                      fontFamily: "Amaranth",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "18px",
                      color: "#000000",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    Remedy
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "18px",
                      color: "#000000",
                      height: "80px",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    “Customers loved the extra surprise with their food!”
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#000000",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    - Food Delivery Partner,
                  </Typography>
                  <Typography
                    vvariant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#000000",
                    }}
                  >
                    Melbourne
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card
                sx={{
                  marginRight: "12px",
                  maxWidth: 300,
                  borderRadius: "30px",
                  background: "#D9D9D9",
                }}
              >
                <CardMedia
                  sx={{ height: "220px" }}
                  image={Whiteclaw}
                  style={{ borderRadius: "25px" }}
                />
                <CardContent sx={{ height: "180px", background: "#D9D9D9" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="ser-head"
                    style={{
                      fontFamily: "Amaranth",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "18px",
                      color: "#000000",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    White Claw
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      width: "230px",
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "18px",
                      color: "#000000",
                      height: "80px",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    “Employees in the office absolutely loved the drinks and it
                    felt like the weekend everyday with White Claw.”
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#000000",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    Office Partner,
                  </Typography>
                  <Typography
                    vvariant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#000000",
                    }}
                  >
                    Brisbane
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card
                sx={{
                  marginRight: "12px",
                  maxWidth: 300,
                  borderRadius: "30px",
                  background: "#D9D9D9",
                }}
              >
                <CardMedia
                  sx={{ height: "220px" }}
                  image={Sanitizer}
                  style={{ borderRadius: "25px" }}
                />
                <CardContent sx={{ height: "180px", background: "#D9D9D9" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="ser-head"
                    style={{
                      fontFamily: "Amaranth",
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "18px",
                      color: "#000000",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    Palmolive Santizer
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      width: "250px",
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "18px",
                      color: "#000000",
                      height: "80px",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    “Thank you to Palmolive where other sanitiser companies are
                    focussed on making profits during this crisis by increasing
                    prices, & Palmolive is reaching out to the consumers.”
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#000000",
                      padding: "12px 12px 2px 0px",
                    }}
                  >
                    - Airbnb Guest,
                  </Typography>
                  <Typography
                    vvariant="body2"
                    color="text.secondary"
                    className="sec-para"
                    style={{
                      fontFamily: "Amaranth",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#000000",
                    }}
                  >
                    Sydney
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Slider>
        </Grid>
        <Grid item lg={12}>
          <div style={{ display: "flex" }}>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 10,
                cursor: "pointer",
              }}
              className="buttons"
              onClick={() => {
                console.log(sliderRef.current);
                sliderRef.current.slickPrev();
              }}
            >
              <KeyboardArrowLeftIcon />
            </Button>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 10,
                borderRadius: 37,
                cursor: "pointer",
              }}
              className="buttons"
              onClick={() => {
                console.log(sliderRef.current);
                sliderRef.current.slickNext();
              }}
            >
              <KeyboardArrowRightIcon />
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default CampScroll;
