import React from "react";
import Header from "../../components/Header/Header";
import Brands from "../../components/BrandsTrust/Brands";
import Footer from "../../components/Footer/Footer";
import FoodDeliveryContent from "./FoodDeliveryContent";
import WhatsIn from "../ShortTermRentals/WhatsIn";
import FoodDeliveryAir from "./FoodDeliveryAir";
import ConsumersInternal from "../../components/Consumers/ConsumersInternal";
import FoodGallery from "./FoodGallery";

const FoodDeliveryMain = () => {
    return (
        <>
            <Header />
            <FoodDeliveryContent />
            <WhatsIn />
            <FoodDeliveryAir />
            <ConsumersInternal />
            <FoodGallery />
            <Brands />
            <Footer />
        </>
    )
}

export default FoodDeliveryMain