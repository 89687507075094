import React from "react";
import Header from "../../components/Header/Header";
import StudiesContent from "./StudiesContent";
import HowWeDo from "./HowWeDo";
import Brands from "../../components/BrandsTrust/Brands";
import Footer from "../../components/Footer/Footer";

const Studies = () => {
return(
    <>
    <Header />
    <StudiesContent />
    <HowWeDo />
    <Brands />
    <Footer />
    </>
)
}
export default Studies