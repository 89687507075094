import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { withTokenrouter } from "./routes";
// import { Mobile } from "./pages/Mobile";
import { MobileView } from "./pages/MobileView";
function App() {
  /* Storing user's device details in a variable*/
  const details = navigator.userAgent;
  const isMobile = window.matchMedia(
    "only screen and (max-width: 480px)"
  ).matches;

  /* Creating a regular expression
        containing some mobile devices keywords
        to search it in details string*/
  const regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
        it returns boolean value*/
  const isMobileDevice = regexp.test(details);

  if (isMobile || isMobileDevice) {
    return <MobileView />;
  } else {
    return <RouterProvider router={withTokenrouter} />;
  }
}

export default App;
