import React from "react";
import PrivacyHeader from "./PrivacyHeader";
import PrivacyFooter from "./PrivacyFooter";
import { Grid } from "@mui/material";
import "../styles/policy.css"

const Policy = () => {
    return (
        <>
            <PrivacyHeader />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="privacy">Privacy Policy</div>
                </Grid>
                <Grid item xs={12}>
                    <div className="priv-1">
                        (“Geezy Exchange”/“We”/ “Us”) are committed to upholding the privacy and
                        security of the information supplied by every person (“User”/“You”)
                        accessing and using any version of the Geezy Exchange Platform(“Platform”)
                        on a compatible device or through a Website Portal. This privacy policy
                        (“Privacy Policy”) briefly provides the manner in which We collect and
                        use User information. By accessing or using the , You signify that
                        You have read, understood and agree to be bound by this Privacy Policy.
                        We reserve the right to update or modify this Privacy Policy at any
                        time without prior notice and such changes shall be effective
                        immediately upon posting the updated or modified Privacy Policy on the website (https://www.geezyx.com) and we shall not be bound to inform You of any modifications hereof. Your access and use of the Platform following any such change constitutes your agreement to follow and be bound by this Privacy Policy, as updated or modified. For this reason, We encourage You to review this Privacy Policy each time You access and use the Platform. Your use of the Platform and any disputes arising therefrom, is subject to this Privacy Policy.
                    </div>
                    <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Our Policy</b></span>
                   <div className="priv2">
                   The Platform is operated by Geezy Exchange - GBR Technologies Ltd and has been created to provide information about the Platform and its activities including, but not restricted to, our online sampling facilitation service, website portal, mobile applications and related services (together with the Platform, the “Services”) to our Service visitors (“you”, “your”). This Privacy Policy sets forth the Platform’s policy with respect to information including personally identifiable data (“Personal Data”) and other information that is collected from visitors to the Platform and Services.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Information We Collect</b></span>
                   <div className="priv2">
                   When You interact with us through the Services, we may collect Personal Data and other information from you, as described below and as per the terms and conditions of the Platform
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> Personal Data That You Provide Through the Services</b></span>
                   <div className="priv2">
                   We collect Personal Data from You when You voluntarily provide such information, such as when You contact us with inquiries, respond to one of our surveys, register for access to the Services, or use certain Services. Wherever the Platform collects Personal Data we make an effort to provide a link to this Privacy Policy.
                   <br />
                   <br />
                   By voluntarily providing us with Personal Data, You are consenting to our use of it in accordance with this Privacy Policy. If You provide Personal Data to the Services, You acknowledge and agree that such Personal Data may be transferred from your current location to the offices and servers of the Platform and the authorized third parties referred to herein.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px',marginTop:'20px'}}> <b>1. Other Information</b></span>
                   <br />
                   <span style={{marginLeft:'130px'}}> <b>a. Non-Identifiable Data</b></span>
                   <div className="priv2">
                   When You interact with the Platform through the Services, we receive and store certain personally non-identifiable information. Such information, which is collected passively using various technologies, cannot presently be used to specifically identify you. The Platform may store such information itself or such information may be included in databases owned and maintained by the Platform affiliates, agents or service providers. The Services may use such information and pool it with other information to track, for example, the total number of visitors to our Site, the number of visitors to each page of our Platform, and the domain names of our visitors' Internet service providers. It is important to note that any information collected through this process is purely incidental and there is no specific attempt is being made to collect such Personal Data in this process.
                    <br />
                    We may also automatically receive and collect certain anonymous information in standard usage logs through the web server, including mobile-identification information obtained from the equivalent of "cookies" sent to the Platform, including mobile network information, standard web log information, traffic to and from our Platform, tracking inside the Platform and any other available information, from:
                     <br />
                     <br />
                   
                    <div>
                    <ul style={{ marginLeft: '3%' }}>
								<li>
									{' '}
									1 .an IP address, assigned to the device used by the User;{' '}
								</li>
								<li>
                               2. the domain server through which the User accesses the Platform and the functions and features therein; and{' '}
								</li>
								<li> 3. the type of device used by the User</li>

							</ul>
                    </div>
                   </div>
                   <div className="priv2">
                    In operating the Services, we may use a technology called "cookies." A cookie is a piece of information that the computer that hosts our Services gives to your browser when You access the Services. Our cookies help provide additional functionality to the Services and help us analyze Services usage more accurately. For instance, our Platform may set a cookie on your browser that allows You to access the Services without needing to remember and then enter a password more than once during a visit to the Platform. In all cases in which we use cookies, we will not collect Personal Data except with your permission. On most web browsers, You will find a “help” section on the toolbar. Please refer to this section for information on how to receive notification when You are receiving a new cookie and how to turn cookies off. We recommend that You leave cookies turned on because they allow You to take advantage of some of the Service features. However, it is purely optional and completely at your discretion, depending on your use.
                    </div>
                    <br />
                   <span style={{marginLeft:'130px'}}> <b> a. Aggregated Personal Data</b></span>
                   <div className="priv2">
                   In an ongoing effort to better understand and serve the users of the Services, the Platform often conducts research on its customer demographics, interests and behavior based on the Personal Data, and other information provided to us. This research may be compiled and analyzed on an aggregate basis, and the Platform may share this aggregate data with its affiliates, agents, and business partners. This aggregate information does not identify You personally. The Platform may also disclose aggregated user statistics in order to describe our services to current and prospective business partners, and to other third parties for other lawful purposes.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Our Use of Your Personal Data and Other Information
</b></span>
                   <div className="priv2">
                   The Platform uses the Personal Data You provide in a manner that is consistent with this Privacy Policy. If You provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if You contact us by email, we will use the Personal Data You provide to answer your question or resolve your problem. Also, if You provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide You with access to such services and to monitor your use of such services. The Platform and its subsidiaries and affiliates (the “Related Companies”) may also use your Personal Data and other personally non-identifiable information collected through the Services to help us improve the content and functionality of the Services, to better understand our users, and to improve the Services. The Platform and its affiliates may use this information to contact You in the future to tell You about services we believe will be of interest to you. If we do so, each marketing communication we send You will contain instructions permitting You to "opt-out" of receiving future marketing communications. In addition, if at any time You wish not to receive any future marketing communications or You wish to have your name deleted from our mailing lists, please contact us as indicated below.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b>Third-Party Advertising Partners</b></span>
                   <div className="priv2">
                   We use third-party service providers to serve advertisements (banners or links) on our behalf across the Internet. These advertising service providers may collect non-identifiable information about your visits to our website, and your interactions with our products and services. Such non-identifiable information does not include your name, address, email addresses, or other personal information. The information is collected through the use of cookies and pixel tags (also known as action tags), which is the industry-standard technology used by most major websites. In addition to the information about your visits to our website, our service providers may also use the information about your visits to other websites to target advertisements for classes available from the Platform.
If the Platform intends on using any Personal Data in any manner that is not consistent with this Privacy Policy, You will be informed of such anticipated use prior to or at the time at which the Personal Data is collected.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Our Disclosure of Your Personal Data and Other Information</b></span>
                   <div className="priv2">
                   The Platform uses the Personal Data You provide in a manner that is consistent with this Privacy Policy. If You provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if You contact us by email, we will use the Personal Data You provide to answer your question or resolve your problem. Also, if You provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide You with access to such services and to monitor your use of such services. The Platform and its subsidiaries and affiliates (the “Related Companies”) may also use your Personal Data and other personally non-identifiable information collected through the Services to help us improve the content and functionality of the Services, to better understand our users, and to improve the Services. The Platform and its affiliates may use this information to contact You in the future to tell You about services we believe will be of interest to you. If we do so, each marketing communication we send You will contain instructions permitting You to "opt-out" of receiving future marketing communications. In addition, if at any time You wish not to receive any future marketing communications or You wish to have your name deleted from our mailing lists, please contact us as indicated below.
                   </div>
                   <br />
                   <span style={{marginLeft:'130px'}}> <b>a. Business Transfers</b></span>
                   <div className="priv2">
                   As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets in the form of intellectual property.
                   </div>
                    <br />
                    <span style={{marginLeft:'130px'}}> <b>a. Related Companies</b></span>
                   <div className="priv2">
                   We may also share your Personal Data with our Related Companies for purposes consistent with this Privacy Policy.
                   </div>
                    <br />
                    <br />
                    <span style={{marginLeft:'130px'}}> <b>a. Related Companies</b></span>
                   <div className="priv2">
                   We may also share your Personal Data with our Related Companies for purposes consistent with this Privacy Policy.
                   </div>
                    <br />
                    <span style={{marginLeft:'130px'}}> <b>a. Agents, Consultants and Related Third Parties
                   </b></span>
                   <div className="priv2">
                   The Platform, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases, and processing payments. When we employ another entity to perform a function of this nature, we only provide them with the information that they need to perform their specific function. 
                   </div>
                    <br />
                    <span style={{marginLeft:'130px'}}> <b>a. Legal Requirements</b></span>
                   <div className="priv2">
                   The Platform may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of the Platform, (iii) act in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) protect against legal liability. 
                   </div>
                    <br />
                    <span style={{marginLeft:'90px'}}> <b> 1. Your Choices</b></span>
                   <div className="priv2">
                   You can visit the Site without providing any Personal Data. If You choose not to provide any Personal Data, You may not be able to use certain Services.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Exclusions</b></span>
                   <div className="priv2">
                   This Privacy Policy does not apply to any Personal Data collected by the Platform other than Personal Data collected through the Services. This Privacy Policy shall not apply to any unsolicited information You provide to the Platform through the Services or through any other means. This includes, but is not limited to, information posted to any public areas of the Services, such as forums, any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, “Unsolicited Information”). All Unsolicited Information shall be deemed to be non-confidential and the Platform shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Children</b></span>
                   <div className="priv2">
                   The Platform does not knowingly collect Personal Data from children under the age of 13. If You are under the age of 13, please do not submit any Personal Data through the Services. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data on the Services without their permission. If You have reason to believe that a child under the age of 13 has provided Personal Data to the Platform through the Services, please contact us, and we will endeavour to delete that information from our databases.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Links to Other Websites</b></span>
                   <div className="priv2">
                   This Privacy Policy applies only to the Services. The Services may contain links to other web sites not operated or controlled by the Platform (the “Third Party Sites”). The policies and procedures we described here do not apply to the Third Party Sites. The links from the Services do not imply that the Platform endorses or has reviewed the Third Party Sites. We suggest contacting those sites directly for information on their privacy policies.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Security</b></span>
                   <div className="priv2">
                   The Platform takes reasonable steps to protect the Personal Data provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the Services may not be secure. Therefore, You should take special care in deciding what information You send to us via email. Please keep this in mind when disclosing any Personal Data to the Platform via the Internet.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Other Terms and Conditions</b></span>
                   <div className="priv2">
                   Your access to and use of the Services is subject to the Terms of Service.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Procedure For Removing User Name from the Records</b></span>
                  <br />
                  <ul style={{ marginLeft: '3%' }}>
								<li>
									{' '}
									1. If and when a User is desirous of having his/her name and other details removed from our records, immediately upon receiving the User’s written request to that effect We shall, subject to the terms hereof, remove and/delete all such information.{' '}
								</li>
								<li>
                               2. If the User no longer wishes to receive notifications about our services, The User may change his/her notification preferences by contacting us at uksupport@geezy.co. We reserve the right to close the User account if the User opts out of receiving certain crucial notices that are required by Us to perform the services through the Platform{' '}
								</li>
							</ul>
                            <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Procedure For Correcting Inaccuracies In User Information</b></span>
                   <div className="priv2">
                   The User may correct or update any information online. In the event of loss of access details, the User may retrieve the same or receive new access details by sending an email to: uksupport@geezy.co
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Changes to the Platform’s Privacy Policy</b></span>
                   <div className="priv2">
                   The Services and our business may change from time to time. As a result, at times it may be necessary for the Platform to make changes to this Privacy Policy. The Platform reserves the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Please review this policy periodically, and especially before You provide any Personal Data. This Privacy Policy was last updated on the date indicated above. Your continued use of the Services after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.
                   </div>
                   <br />
                   <span style={{marginLeft:'90px'}}> <b> 1. Access to Information: Contacting the Platform</b></span>
                   <div className="priv2">
                   To keep your Personal Data accurate, current, and complete, please contact us as specified below. We will take reasonable steps to update or correct Personal Data in our possession that You have previously submitted via the Services.
                   <br />
                   <br />
                   Please also feel free to contact us if You have any questions about the Platform’s Privacy Policy or the information practices of the Services.
                   <br />
                   <br />
                   You may contact us as follows:
                   <br />
                   <br />
                   Email ID: uksupport@geezy.co
                   <br />
                   <br />
                   Address : 16192 Coastal Highway Lewes, DE 19958 USA
                   </div>
                   <br />
                </Grid>
            </Grid>
            <PrivacyFooter />
        </>
    )
}
export default Policy
