export const Globe = ({ width = "100%", height = "800px" }) => {
  return (
    <iframe
      src="/globe"
      frameborder="0"
      title="globe"
      height={height}
      width={width}
    />
  );
};
