import { Helmet } from "react-helmet";

export const MobileView = () => {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="../../mobile/assets/css/custom.css"
        />
        <script src="../../mobile/assets/js/custom.js"></script>
      </Helmet>

      <header className="mobile-header">
        <div className="mobile-container">
          <div className="header-row">
            <div className="logo-box">
              <a href="index.html">
                <img src="../../mobile/assets/images/logos/Geezy X logo.png" />
              </a>
            </div>
            <div className="header-menu-box">
              <div className="header-menu-box-inner">
                <a href="#" className="mobile-menu-toggle">
                  <span className="toggle-span toggle-span-top" />
                  <span className="toggle-span toggle-span-center" />
                  <span className="toggle-span toggle-span-bottom" />
                </a>
              </div>
              <div className="menu-container">
                <div className="menu-container-inner">
                  <ul className="mobile-menu-links">
                    <li>
                      <a href="#sampling">Solutions</a>
                      <ul className="mobile-sub-menu">
                        <li>
                          <a href="#sampling">Short Term Rentals(AirBnbs)</a>
                        </li>
                        <li>
                          <a href="#sampling">Food Delivery</a>
                        </li>
                        <li>
                          <a href="#sampling">Office Spaces</a>
                        </li>
                        <li>
                          <a href="#sampling">Fitness Centers</a>
                        </li>
                        <li>
                          <a href="#sampling">Restaurants And Cafes</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#case_studies">Case Studies</a>
                    </li>
                    <li>
                      <a href="#about_us">About Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="home-banner">
        <div className="mobile-container-h">
          <div className="banner-text mobile-container">
            <h2>
              Take Product Sampling to a new level, and reach a highly engaged
              audience with Geezy X
            </h2>
            <p>
              The best way to engage with your target audience is by customising
              the brand experience.
            </p>
            <p>
              Refining your sampling campaign by interacting with consumers at
              the relevant moments will encourage trial, increase brand
              awareness, and drive sales.
            </p>
          </div>
          <div className="banner-images">
            <div className="banner-images-slides">
              <ul className="banner-fade-slide">
                <li>
                  <div className="banner-slide-box">
                    <div className="banner-slide-box-inner">
                      <div className="banner-image-boxed">
                        <img src="../../mobile/assets/images/slider/slider1.png" />
                      </div>
                      <div className="banner-image-boxed">
                        <img src="../../mobile/assets/images/slider/slider2.png" />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="banner-slide-box">
                    <div className="banner-slide-box-inner">
                      <div className="banner-image-boxed">
                        <img src="../../mobile/assets/images/slider/slider3.png" />
                      </div>
                      <div className="banner-image-boxed">
                        <img src="../../mobile/assets/images/slider/slider4.png" />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="banner-slide-box">
                    <div className="banner-slide-box-inner">
                      <div className="banner-image-boxed">
                        <img src="../../mobile/assets/images/slider/slider5.png" />
                      </div>
                      <div className="banner-image-boxed">
                        <img src="../../mobile/assets/images/slider/slider6.png" />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="banner-slide-box">
                    <div className="banner-slide-box-inner">
                      <div className="banner-image-boxed">
                        <img src="../../mobile/assets/images/slider/slider7.png" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="unlock-accordion-section" id="sampling">
        <div className="mobile-container">
          <div className="unlock-section">
            <div className="section-heading">
              <h2>
                Our Sampling Channels
                <span className="secondry-color" />
              </h2>
              <p>
                Geezy X has the largest portfolio of sampling channels available
                globally. These channels enable brands and agencies to create
                sampling campaigns that engage with a relevant audience, at
                relevant times, and sample at point of use.
              </p>
            </div>
            <div className="unlock-accordions">
              <div className="accordion-container">
                <div className="accordion-item">
                  <div className="accordion-header">
                    <a href="#">
                      Food Delivery Sampling
                      <img src="../../mobile/assets/images/arrow-down.svg" />
                    </a>
                  </div>
                  <div className="accordion-body">
                    <div className="accordion-body-content">
                      <p>
                        Hand your samples to ideal customers by sampling your
                        products with 125k restaurant partners globally. Done
                        through Uber Eats, Deliveroo, Doordash and other
                        delivery services. We guarantee a 100% open rate,
                        ambient, chilled, frozen, or alcohol.&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header">
                    <a href="#">
                      Office/ Co-Working Sampling
                      <img src="../../mobile/assets/images/arrow-down.svg" />
                    </a>
                  </div>
                  <div className="accordion-body">
                    <div className="accordion-body-content">
                      <p>
                        Reach the right consumers in targeted workplace
                        environment to create brand awareness. We offer more
                        than 4000+ co-working and office spaces to establish a
                        strong brand name.&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header">
                    <a href="#">
                      Fitness Centers Sampling
                      <img src="../../mobile/assets/images/arrow-down.svg" />
                    </a>
                  </div>
                  <div className="accordion-body">
                    <div className="accordion-body-content">
                      <p>
                        With over 2600+ gyms across the globe, our gym sampling
                        services help in targeting health-conscious audience who
                        would love to engage with your brand.&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header">
                    <a href="#">
                      AirBnb Sampling
                      <img src="../../mobile/assets/images/arrow-down.svg" />
                    </a>
                  </div>
                  <div className="accordion-body">
                    <div className="accordion-body-content">
                      <p>
                        Our 85K+ short-term rental spaces globally bring an
                        array of opportunities for brand owners to place their
                        product samples in locations that will have maximum
                        customer engagement.&nbsp;&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header">
                    <a href="#">
                      Restaurant &amp; Cafes Sampling
                      <img src="../../mobile/assets/images/arrow-down.svg" />
                    </a>
                  </div>
                  <div className="accordion-body">
                    <div className="accordion-body-content">
                      <p>
                        With over 3500+ hotels across globe, distribute your
                        samples to the those who align the best with your
                        requirement and watch your sales boost up
                        higher.&nbsp;&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="products-section">
        <div className="mobile-container">
          <div className="section-heading">
            <h2>
              Additional Services <span className="secondry-color" />
            </h2>
          </div>
          <div className="products-list">
            <div className="product-item">
              <div className="product-item-inner d-flex align-center">
                <div className="product-image w-100">
                  <img
                    src="../../mobile/assets/images/common/addition1.png"
                    className="fullImg"
                  />
                </div>
                <div className="product-content">
                  <div className="product-title">
                    <h3>Product Sampling</h3>
                  </div>
                  <div className="product-desc">
                    <p>
                      Collect customer data and boost awareness &amp; engagement
                      through our product sampling services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-item">
              <div className="product-item-inner d-flex align-center">
                <div className="product-image w-100">
                  <img
                    src="../../mobile/assets/images/common/addition4.png"
                    className="fullImg"
                  />
                </div>
                <div className="product-content">
                  <div className="product-title">
                    <h3>Branded Food Delivery Bag</h3>
                  </div>
                  <div className="product-desc">
                    <p>
                      Distribute samples in branded food delivery bags for
                      better customer engagement.&nbsp;&nbsp;
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-item">
              <div className="product-item-inner d-flex align-center">
                <div className="product-image w-100">
                  <img
                    src="../../mobile/assets/images/common/addition3.png"
                    className="fullImg"
                  />
                </div>
                <div className="product-content">
                  <div className="product-title">
                    <h3>Insert Placement</h3>
                  </div>
                  <div className="product-desc">
                    <p>
                      Place consumer-relevant products in an effective manner to
                      target the right audiences efficiently.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-item">
              <div className="product-item-inner d-flex align-center">
                <div className="product-image w-100">
                  <img
                    src="../../mobile/assets/images/common/addition2.png"
                    className="fullImg"
                  />
                </div>
                <div className="product-content">
                  <div className="product-title">
                    <h3>In App Promotions in Food Delivery</h3>
                  </div>
                  <div className="product-desc">
                    <p>
                      Effective use of in-app promotions to keep consumers up to
                      date on upgrades and availabilities{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="counter-section">
        <div className="counter-section-inner">
          <div className="counter-box">
            <label className="counter">20 M+</label>
            <span>Samples</span>
          </div>
          <div className="counter-box">
            <label className="counter">400+</label>
            <span>Brands</span>
          </div>
          <div className="counter-box">
            <label className="counter">1000+</label>
            <span>Campaigns</span>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="mobile-container_case_slider_main" id="case_studies">
        <div className="case_slider_main">
          <h1>Case Studies</h1>
          <div className="">
            <ul className="case_slider row">
              <li>
                <div className="card">
                  <img
                    src="../../mobile/assets/images/common/case_study2.png"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      Palmolive sanitiser x Geezy X
                    </h5>
                    <p className="card-text">
                      The team at Palmolive Sanitiser collaborated with Geezy X
                      to distribute 50,000 samples across AirBnbs throughout
                      Australia to demonstrate to their potential customers the
                      effectiveness of 500ml of their sanitiser.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <img
                    src="../../mobile/assets/images/common/case_study1.png"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      Bringing The ‘Remedy’ To Its Health-Conscious Audience
                    </h5>
                    <p className="card-text">
                      Remedy partnered with Geezy X to successfully distribute
                      14,000+ samples across the food delivery network, focusing
                      on orders made at dinner time.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <img
                    src="../../mobile/assets/images/common/case_study3.png"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Jim Beam</h5>
                    <p className="card-text">
                      The team at Jim Beam collaborated with Geezy X to
                      distribute around 15,000 samples using our food delivery
                      network across various cities in Australia to allow
                      potential customers the opportunity to taste these rich,
                      timeless spirits.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <br />
        <br />
        <p className="caseStudy">
          For more information about the case studies or for our media kit
          please reach out to us at{" "}
          <a href="mailto:partnerships@geezy.co">partnerships@geezy.co</a>
        </p>
      </section>
      {/*  */}
      <section className="cms-features-section" id="about_us">
        <div className="mobile-container">
          <div className="section-heading">
            <h2>About us</h2>
          </div>
          <div className="cms-short-desc">
            <p>
              We are proud to be a truly global brand, with a presence in over
              15 countries worldwide.
            </p>
            <p>
              From our humble beginnings, we have grown exponentially over the
              years and expanded our reach to serve business in different parts
              of the world.
            </p>
            <p>
              Our global presence is a testament to our commitment to providing
              quality services that meet the needs of our diverse customer base.
              Whether you're in North America, Europe, Asia, or any other part
              of the world, you can count on Geezy X to deliver exceptional
              value and services.
            </p>
          </div>
        </div>
      </section>
      <section className="testmonial-section">
        <div className="mobile-container">
          <div className="section-heading">
            <h2>Present in more than 15+ countries, and still growing...</h2>
          </div>
          <div className="testmonial-box">
            <ul className="testmonial-slider">
              <li>
                <div className="testmonial-item">
                  <div className="testmonial-content">
                    <h1>Canada</h1>
                  </div>
                </div>
              </li>
              <li>
                <div className="testmonial-item">
                  <div className="testmonial-content">
                    <h1>Belgium </h1>
                  </div>
                </div>
              </li>
              <li>
                <div className="testmonial-item">
                  <div className="testmonial-content">
                    <h1>Portugal</h1>
                  </div>
                </div>
              </li>
              <li>
                <div className="testmonial-item">
                  <div className="testmonial-content">
                    <h1>UK</h1>
                  </div>
                </div>
              </li>
              <li>
                <div className="testmonial-item">
                  <div className="testmonial-content">
                    <h1>USA</h1>
                  </div>
                </div>
              </li>
              <li>
                <div className="testmonial-item">
                  <div className="testmonial-content">
                    <h1>New Zealand</h1>
                  </div>
                </div>
              </li>
              <li>
                <div className="testmonial-item">
                  <div className="testmonial-content">
                    <h1>India</h1>
                  </div>
                </div>
              </li>
              <li>
                <div className="testmonial-item">
                  <div className="testmonial-content">
                    <h1>Australia </h1>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="integration-section">
        <div className="mobile-container">
          <div className="section-heading">
            <h2>Brands that trust us</h2>
          </div>
          <div className="integrations-logos">
            <div className="integrations-logos-inner">
              <ul className="integration-logos">
                <li>
                  <img src="../../mobile/assets/images/brands/Dare Coffee 1_layerstyle.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group 1.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group 2.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group 2741.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group 2742.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group 3.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group 4.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group 6.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group 7.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group-1.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group-2.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group-3.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group-4.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group-5.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Group.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/LOGO-white.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/path3298.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/path4185.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Vector-1.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Vector.png" />
                </li>
                <li>
                  <img src="../../mobile/assets/images/brands/Zespri_Kiwifruit_(logo) 1_layerstyle.png" />
                </li>
              </ul>
            </div>
            <h1 className="more_brands">500 More...</h1>
          </div>
        </div>
      </section>
      <footer className="mobile-footer">
        <div className="mobile-container">
          <div className="footer-logo-social">
            <div className="site-logo footer-left">
              <img src="../../mobile/assets/images/logos/Geezy X logo.png" />
            </div>
            <div className="social-logos footer-right">
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.facebook.com/people/GeezyX/100091962520311/"
                    target="_blank"
                  >
                    <img src="../../mobile/assets/images/facebook-01.svg" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/geezy.exchange/"
                    target="_blank"
                  >
                    <img src="../../mobile/assets/images/instagram-01.svg" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/showcase/geezyx/"
                    target="_blank"
                  >
                    <img src="../../mobile/assets/images/Linkedin-01.svg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-main-content">
            <div className="footer-left">
              <ul className="footer-menu">
                <li>
                  <a href="#" className="hasSub">
                    Solutions
                    <img src="../../mobile/assets/images/footer-menu-drop.svg" />
                  </a>
                  <ul className="footer-submenu">
                    <li>
                      <a href="#sampling">Short Term Rentals(AirBnbs)</a>
                    </li>
                    <li>
                      <a href="#sampling">Food Delivery</a>
                    </li>
                    <li>
                      <a href="#sampling">Office Spaces</a>
                    </li>
                    <li>
                      <a href="#sampling">Fitness Centers</a>
                    </li>
                    <li>
                      <a href="#sampling">Restaurants And Cafes</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#case_studies">Case Studies</a>
                </li>
                <li>
                  <a href="#about_us">About Us</a>
                </li>
              </ul>
            </div>
            <div className="footer-right">
              <div className="contact-footer">
                <div className="footer-contact-box">
                  <label>Mail:</label>
                  <a href="mailto:partnerships@geezy.co">
                    partnerships@geezy.co
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-privacy">
            <div className="footer-privacy-inner">
              <span>© 2023 Geezy X</span>
            </div>
          </div>
        </div>
      </footer>
      <section className="footer-btns">
        <a href="#" className="foot-demo foot-btn" data-id="bookDemo">
          Contact Us
          <img src="../../mobile/assets/images/brands/open.svg" />
        </a>
      </section>
      <div
        className="knowMore-popup popup-modal"
        id="bookDemo"
        style={{ display: "none" }}
      >
        <div className="popup-box">
          <div className="popup-box-inner">
            <div className="section-heading">
              <h2>To book a demo now:</h2>
            </div>
            <div className="book-demo-box">
              <div className="subhead">
                <h6>Reach out to us at</h6>
              </div>
              <div className="pop-email">
                <a href="mailto:partnerships@geezy.co">partnerships@geezy.co</a>
              </div>
              {/*          <div className="orText">
                <span>or</span>
              </div>
              <div className="whatapp-btn">
                <a href="#">
                  <img src="../../mobile/assets/images/whatsapp.svg" /> WhatsApp
                  Now
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="popup-overlay" />
      </div>
    </>
  );
};
