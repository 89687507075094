import { Grid } from "@mui/material";
import React from "react";
import "../styles/consumer.css"

const ConsumersInternal = () => {
    return (
       <>
       <Grid container className="consumer-main">
<Grid item lg={12} className="consumer-items">
<div>
    <span className="para-1-1">20M+</span>
    <p className="span-1">Products Sampled</p>
</div>
<div>
    <span className="para-1">400+</span>
    <p className="span-1">Brands</p>
</div>
<div>
    <span className="para-1">1000+</span>
    <p className="span-1">Campaigns</p>
</div>
</Grid>
       </Grid>
       <Grid item lg={12} className="consumer-content-box">
       <div  className="consumer-content">
       <div>
        <p style={{margin:' 12px 39px 11px;'}}>Have Been Giving Consumers </p>
       <p className="what-they" style={{margin:'6px'}}>What They Love</p>
        </div>
       </div>
       </Grid>
      
       </>
    )
}

export default ConsumersInternal