import "../styles/globe.css";
import { Globe } from "../Globe";

export default function GlobeMap() {
  return (
    <div
      className="main-part"
      style={{
        position: "relative",
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        background: "white",
        top: 60,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-60px",
          left: "87px",
          color: "black",
          fontFamily: "Amaranth",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "54px",
          lineHeight: "78px",
        }}
      >
        Our Global Network
      </div>
      <div
        style={{
          position: "relative",
          top: "136px",
          display: "flex",
          flexDirection: "row",
          marginTop: "30px",
        }}
      >
        <Globe height={878} width={600} />
        <div style={{ marginLeft: "150px" }}>
          <div
            style={{
              width: "370px",
              color: "black",
              fontFamily: "Amaranth",
              fontSize: "38px",
              fontWeight: 400,
              lineHeight: "58px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
          >
            Present in more than 15+ countries, and still growing...
          </div>
          <div
            style={{
              width: "450px",
              marginTop: "20px",
              fontFamily: "Amaranth",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "22px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
          >
            We are proud to be a truly global brand, with a presence in over 15
            countries worldwide.
            <br />
            <br />
            From our humble beginnings, we have grown exponentially over the
            years and expanded our reach to serve business in different parts of
            the world.
            <br />
            <br />
            Our global presence is a testament to our commitment to providing
            quality services that meet the needs of our diverse customer base.
            Whether you're in North America, Europe, Asia, or any other part of
            the world, you can count on Geezy X to deliver exceptional value and
            services.
          </div>
          <div
            style={{
              marginRight: "127px",
              marginTop: "40px",
            }}
          >
            <button
              style={{
                cursor: "pointer",
                justifyContent: "center",
                alignItems: "center",
                padding: "11px 36px 11px 39px",
                background: "#0064f4",
                borderRadius: "6px",
                border: "none",
              }}
              onClick={() => {
                window.scrollTo({ top: 10000, behavior: "smooth" });
              }}
            >
              <label
                className=""
                style={{
                  width: "119px",
                  height: "20px",
                  cursor: "pointer",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "20px",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Contact Now
              </label>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
