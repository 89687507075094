import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export const CustomPagination = ({ count, page, handleChange }) => {
  return (
    <Stack spacing={2} alignItems="center" style={{ padding: "50px 0" }}>
      <Pagination
        count={count}
        page={page}
        color="primary"
        onChange={handleChange}
        sx={{
          "& .css-2tobv4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
            {
              background: "rgba(134, 56, 229, 0.25)",
              color: "rgba(40, 44, 53, 1)",
            },
        }}
      />
    </Stack>
  );
};
