import React from "react";
import Header from "../../components/Header/Header";
import StudiesContent from "../../pages/CaseMainStudies/StudiesContent";
import BlogList from "./blogList";
import Brands from "../../components/BrandsTrust/Brands";
import Footer from "../../components/Footer/Footer";
import { Grid } from "@mui/material";

const Blogs = () => {
  return (
    <Grid>
      <Header />
      <StudiesContent />
      <BlogList />
      <Brands />
      <Footer />
    </Grid>
  );
};
export default Blogs;
