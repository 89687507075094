import React from "react";
import Header from "../../components/Header/Header";
import WhatsIn from "../ShortTermRentals/WhatsIn";
import Brands from "../../components/BrandsTrust/Brands";
import Footer from "../../components/Footer/Footer";
import HotelContent from "./HotelContent";
import HotelAir from "./HotelAir";
import ConsumersInternal from "../../components/Consumers/ConsumersInternal";
import HotelGallery from "./HotelGallery";

const HotelMain = () => {
    return(
        <>
        <Header />
        <HotelContent />
            <WhatsIn />
            <HotelAir />
            <ConsumersInternal/>
            <HotelGallery />
            <Brands />
            <Footer />
        </>
    )
}
export default HotelMain