import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import image1 from "../../assests/Rectangle 114.png";
import image2 from "../../assests/Rectangle 75.png";
import image3 from "../../assests/Rectangle 113.png";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../components/styles/coco.css";
import { services } from "../../service/ServiceConstants";
import moment from "moment";
import Loader from "../../components/Spinner";

const BlogContent = () => {
  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  console.log(location?.search, "location?.search");

  const query = new URLSearchParams(location?.search);

  const Id = query.get("id");

  const fetchBlog = async (Id) => {
    setLoading(true);
    const response = await services.searchBlogByBlogId(Id);
    if (response?.data?.contents) {
      setBlogList(response?.data?.contents);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBlog(Id);
  }, [Id]);

  return (
    <>
      {!loading ? (
        <div style={{ padding: "115px" }}>
          <div
            onClick={() => {
              navigate("/in-case-studies");
            }}
            style={{
              cursor: "pointer",
              width: "4%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ArrowBackIcon style={{ fontSize: "16px" }} /> Back
          </div>

          <div className="contentHeading">
            {" "}
            {blogList?.title?.includes(":")
              ? blogList?.title?.split(":")[0]
              : blogList?.title}
          </div>
          <div className="contentDate" style={{ marginBottom: "16px" }}>
            {blogList?.createdAt
              ? moment(blogList?.createdAt).format("DD/MM/YYYY")
              : ""}
          </div>
          <div
            className="contentData"
            style={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "19px",
              lineHeight: "22px",
              // letterSpacing: "0.04em",
              color: "#282c35",
              marginBottom: "15px",
            }}
          >
            {blogList?.desc}
          </div>
          <div
            className="image1"
            style={{
              width: "1270px",
              height: "473px",
              borderRadius: "40px",
              border: "1px solid transparent",
            }}
          >
            <img
              src={blogList?.images?.length && blogList?.images[0]}
              style={{
                width: "1270px",
                height: "473px",
                borderRadius: "40px",
                border: "1px solid transparent",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="group2769">
            <div
              className="contentText1"
              style={{
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "19px",
                lineHeight: "22px",
                // letterSpacing: "0.04em",
                color: "#282c35",
              }}
            >
              {blogList?.body1}
              <br />
              <br />
              {blogList?.body2}
              <br />
              <br />
            </div>
          </div>
          <div className="group2770">
            <div
              className="contextText2"
              style={{
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "19px",
                lineHeight: "22px",
                // letterSpacing: "0.04em",
                color: "#282c35",
              }}
            >
              {blogList?.body3}
            </div>
          </div>
        </div>
      ) : (
        <Loader relative="relative" top="100px" />
      )}
    </>
  );
};
export default BlogContent;
