import React from "react";
import Header from "../../components/Header/Header";
import CocoEarned from "./CocoEarned";
import Footer from "../../components/Footer/Footer";
import SuggestedReads from "./SuggestedReads";
import Brands from "../../components/BrandsTrust/Brands";

const CaseStudiesContent = () => {
return(
    <>
    <Header />
    <CocoEarned />
    <SuggestedReads />
    <Brands />
    <Footer />
    </>
)
}

export default CaseStudiesContent