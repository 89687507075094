import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const Label = styled(Box)({
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: 999999,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});
const Loader = ({ relative, top }) => {
  return (
    <Label style={{ position: relative || "absolute", top: top ? top : "" }}>
      <CircularProgress sx={{ color: "rgba(0, 83, 122, 1)" }} />
    </Label>
  );
};

export default Loader;
