import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const DateAndTime = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "30px",
  letterSpacing: "0.04em",
  color: "#282c35",
});

export const CardDescription = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "17px",
  lineHeight: "30px",
  letterSpacing: "0.04em",
  color: "#282c35",
});
