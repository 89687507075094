import { Grid } from "@mui/material";
import React from "react";
import "../styles/camp.css";
import CampScroll from "./CampScroll";

const Campaign = () => {
  return (
    <Grid
      container
      className="camp-conatiner"
      style={{ marginTop: "150px", paddingLeft: "75px" }}
    >
      <Grid item lg={3} className="camp-item1">
        <h1 className="camp-head">Engagement=ROI</h1>
        <p className="camp-para" style={{ width: "406px" }}>
        Higher consumer engagement and better consumer experience lead to higher ROI for our brand partners.
        </p>
      </Grid>
      <Grid item lg={9} className="camp-item2">
        <CampScroll />
      </Grid>
    </Grid>
  );
};
export default Campaign;
