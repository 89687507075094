import { createBrowserRouter, Navigate } from "react-router-dom";
import LandingPage from "../pages/landingPage/LandingPage";
import ShortTermRentals from "../pages/ShortTermRentals/ShortTermRental";
import FoodDeliveryMain from "../pages/FoodDelivery/FoodDeliveryMain";
import RideShareMain from "../pages/RideShare/RideShareMain";
import SaloonMain from "../pages/Saloons/SaloonMain";
import OfficeSpaceMain from "../pages/OfficeSpace/OfficeSpaceMain";
import FitnessCenterMain from "../pages/FitnessCenter/FitnessCenterMain";
import HotelMain from "../pages/HotelsAndRestaurants/HotelMain";
import AboutUs from "../pages/AboutUs/AboutUs";
import CaseStudiesContent from "../pages/CaseStudiesMain/CaseStudiesContent";
import Studies from "../pages/CaseMainStudies/Studies";
import Blogs from "../components/Blogs/blogs";
import Blog from "../components/Blogs/blog";
import CaseStudy from "../pages/CaseMainStudies/caseStudy";
import Policy from "../components/PrivacyPolicy/Policy";

export const withTokenrouter = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/in-short-term-rentals",
    element: <ShortTermRentals />,
  },
  {
    path: "/in-food-delivery",
    element: <FoodDeliveryMain />,
  },
  {
    path: "/in-office-spaces",
    element: <OfficeSpaceMain />,
  },
  {
    path: "/in-gyms",
    element: <FitnessCenterMain />,
  },
  {
    path: "/in-hotels-restaurants",
    element: <HotelMain />,
  },
  {
    path: "/in-about-Us",
    element: <AboutUs />,
  },
  {
    path: "/in/case-studies/have-a-sip-of-lipton-tea",
    element: <CaseStudiesContent />,
  },
  {
    path: "/in-case-studies",
    element: <Studies />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/case-study",
    element: <CaseStudy />,
  },
  {
    path: "/in-privacy-policy",
    element: <Policy />,
  },
]);
