import React from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import image1 from "../../assests/Rectangle 114.png"; 
import image2 from "../../assests/Rectangle 75.png"; 
import image3 from "../../assests/Rectangle 113.png"; 
import { Navigate, useNavigate } from "react-router-dom";
import "../../components/styles/coco.css"
 const CocoEarned = () => {
    const navigate = useNavigate();
return(
    <>
<div style={{    padding: '115px'
}}>
				<div
					className="backButton"
					style={{cursor:'pointer'}}
					onClick={() => {
						navigate('/');
					}}
				>
					<KeyboardArrowLeftIcon style={{marginBottom: '-6px'}}/>
					<span>Back</span>
				</div>
				<div className="contentHeading">How Coca Cola Earned?</div>
				<div className="contentDate">13/03/2023</div>
				<div className="contentData">
					Introductiion about the blog-to showcase and monetize their media
                    <br></br> 
                    by connecting them with various brands whose different product
                    <br></br>
                     offerings
					can be marketed with the media.
				</div>
                <div className="image1">
						<img src={image1} />
					</div>
				<div className="group2769">
					<div className="contentText1">
						Lorem Ipsum is simply dummy text of the printing and typesetting
						industry. Lorem Ipsum has been the industry's standard dummy text
						ever since the 1500s, when an unknown printer took a galley of type
						and scrambled it to make a type specimen book. It has survived not
						only five centuries, but also the leap into electronic typesetting,
						remaining essentially unchanged. It was popularised in the 1960s
						with the release of Letraset sheets containing Lorem Ipsum passages,
						and more recently with desktop publishing software like Aldus
						PageMaker including versions of Lorem Ipsum.
						<br />
						<br />
						Lorem Ipsum is simply dummy text of the printing and typesetting
						industry. Lorem Ipsum has been the industry's standard dummy text
						ever since the 1500s, when an unknown printer took a galley of type
						and scrambled it to make a type specimen book. It has survived not
						only five centuries, but also the leap into electronic typesetting,
						remaining essentially unchanged. It was popularised in the 1960s
						with the release of Letraset sheets containing Lorem Ipsum passages,
						and more recently with desktop publishing software like Aldus
						PageMaker including versions of Lorem Ipsum.
						<br />
						<br />
						Lorem Ipsum is simply dummy text of the printing and typesetting
						industry. Lorem Ipsum has been the industry's standard dummy text
						ever since the 1500s, when an unknown printer took a galley of type
						and scrambled it to make a type specimen book. It has survived not
						only five centuries, but also the leap into electronic typesetting,
					</div>
					<div className="image2">
						<img src={image2} />
					</div>
				</div>
                <div className="group2770">
                <div className="image3">
						<img src={image3} />
					</div>
                    <div className="contextText2">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                     Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                     when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                      It has survived not only five centuries, but also the leap into electronic typesetting, 
                      remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset 
                      sheets containing Lorem Ipsum passages, and more recently with desktop 
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </div>
                </div>
                <div className="contextText3">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                 simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of
                  the printing and typesetting industry simply dummy text of the printing and typesettingbut also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                nd more recently with desktop publishing software like Aldus PageMaker.
                </div>
			</div>
</>
)
 }
 export default CocoEarned