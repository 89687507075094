import React from "react";
import "../../components/styles/content.css";
import { useEffect } from "react";
import Batman from "../../assests/Batman.png";
import GC1 from "../../assests/GC1.png";
import G8 from "../../assests/Gallery/G8.png";
import G20 from "../../assests/Gallery/G20.png";
import R151 from "../../assests/Rectangle 151.png";
import R152 from "../../assests/Rectangle 152.png";
import R98 from "../../assests/Rectangle 98.png";
import R97 from "../../assests/Rectangle 97.png";
import Video from "../../components/Constant/Video";

const OfficeSpaceContent = () => {
  const [move, setMove] = React.useState(false);
  const [moveCards, setMoveCards] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setMove(true);
    }, 5000);

    setTimeout(() => {
      setMoveCards(true);
    }, 8000);
  }, []);

  return (
    <>
      <video autoPlay loop muted className="vide0-bg">
        <source src={Video} type="video/mp4" />
      </video>
      <div className={`main-content ${move ? "mainMove1" : ""}`}>
        <span className="food-span-office">Office Spaces</span>
        <h1 className="main-head-cowork">
          Surprise And Delight Consumers With Samples Whilst At Work.
        </h1>
        <p
          className="main-para-short"
          style={{ width: "560px", position: "relative", left: "-30px" }}
        >
          With access to over 4,000 office spaces, across all major cities, you
          can get your product into the hands of targeted consumers. Sampling
          via offices creates brand awareness, trial and drives word of mouth
          recommendations.
        </p>
        <div style={{ marginRight: "481px" }}>
          <button
            className="contactUs-btn1"
            onClick={() => {
              window.scrollTo({ top: 8000, behavior: "smooth" });
            }}
          >
            <label className="label-contact">Contact Now</label>
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: "absolute",
          top: "0",
          right: "0",
          overflow: "hidden",
        }}
        className="cards-main-div"
      >
        <div
          className={`vertical-cards column-card-one ${
            moveCards ? "vertical-cards1" : ""
          }`}
        >
          <section id="marquee" class={`${moveCards ? "ani" : ""}`}>
            <div class="container">
              <div class="pic-container">
                <div class="pic">
                  <img src={G8} alt="img" />
                </div>
                <div class="pic">
                  <img src={Batman} alt="img" />
                </div>
                <div class="pic">
                  <img src={GC1} alt="img" />
                </div>
                <div class="pic">
                  <img src={G20} alt="img" />
                </div>
                <div class="pic">
                  <img src={G8} alt="img" />
                </div>
                <div class="pic">
                  <img src={Batman} alt="img" />
                </div>
                <div class="pic">
                  <img src={GC1} alt="img" />
                </div>
                <div class="pic">
                  <img src={G20} alt="img" />
                </div>
                <div class="pic">
                  <img src={G8} alt="img" />
                </div>
                <div class="pic">
                  <img src={Batman} alt="img" />
                </div>
                <div class="pic">
                  <img src={GC1} alt="img" />
                </div>
                <div class="pic">
                  <img src={G20} alt="img" />
                </div>
                <div class="pic">
                  <img src={G8} alt="img" />
                </div>
                <div class="pic">
                  <img src={Batman} alt="img" />
                </div>
                <div class="pic">
                  <img src={GC1} alt="img" />
                </div>
                <div class="pic">
                  <img src={G20} alt="img" />
                </div>
                <div class="pic">
                  <img src={G8} alt="img" />
                </div>
                <div class="pic">
                  <img src={Batman} alt="img" />
                </div>
                <div class="pic">
                  <img src={GC1} alt="img" />
                </div>
                <div class="pic">
                  <img src={G20} alt="img" />
                </div>
                <div class="pic">
                  <img src={G8} alt="img" />
                </div>
                <div class="pic">
                  <img src={Batman} alt="img" />
                </div>
                <div class="pic">
                  <img src={GC1} alt="img" />
                </div>
                <div class="pic">
                  <img src={G20} alt="img" />
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <div
          className={`vertical-cards column-card-one ${
            moveCards ? "vertical-cards1" : ""
          }`}
        >
          <div className="vertical-card">
            <img src={G8} alt="img" />
          </div>
          <div className="vertical-card">
            <img src={Batman} alt="img" />
          </div>
          <div className="vertical-card">
            <img src={GC1} alt="img" />
          </div>
          <div className="vertical-card">
            <img src={G20} alt="img" />
          </div>
        </div> */}
        <div
          className={`vertical-cards column-card-two ${
            moveCards ? "vertical-cards2" : ""
          }`}
        >
          <section id="marquee2">
            <div class="container2">
              <div class="pic-container2">
                <div class="pic">
                  <img src={R151} alt="img" />
                </div>
                <div class="pic">
                  <img src={R98} alt="img" />
                </div>
                <div class="pic">
                  <img src={R97} alt="img" />
                </div>
                <div class="pic">
                  <img src={R152} alt="img" />
                </div>
                <div class="pic">
                  <img src={R151} alt="img" />
                </div>
                <div class="pic">
                  <img src={R98} alt="img" />
                </div>
                <div class="pic">
                  <img src={R97} alt="img" />
                </div>
                <div class="pic">
                  <img src={R152} alt="img" />
                </div>
                <div class="pic">
                  <img src={R151} alt="img" />
                </div>
                <div class="pic">
                  <img src={R98} alt="img" />
                </div>
                <div class="pic">
                  <img src={R97} alt="img" />
                </div>
                <div class="pic">
                  <img src={R152} alt="img" />
                </div>
                <div class="pic">
                  <img src={R151} alt="img" />
                </div>
                <div class="pic">
                  <img src={R98} alt="img" />
                </div>
                <div class="pic">
                  <img src={R97} alt="img" />
                </div>
                <div class="pic">
                  <img src={R152} alt="img" />
                </div>
                <div class="pic">
                  <img src={R151} alt="img" />
                </div>
                <div class="pic">
                  <img src={R98} alt="img" />
                </div>
                <div class="pic">
                  <img src={R97} alt="img" />
                </div>
                <div class="pic">
                  <img src={R152} alt="img" />
                </div>
                <div class="pic">
                  <img src={R151} alt="img" />
                </div>
                <div class="pic">
                  <img src={R98} alt="img" />
                </div>
                <div class="pic">
                  <img src={R97} alt="img" />
                </div>
                <div class="pic">
                  <img src={R152} alt="img" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default OfficeSpaceContent;
