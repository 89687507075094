import { Grid } from "@mui/material";
import React from "react";
import Cococola from "../../assests/cococola.png";
import Lipton from "../../assests/lipton.png";
import Gin from "../../assests/gin.png";
import "../../components/styles/caseStudies.css"
import { useNavigate } from 'react-router-dom';

const SuggestedReads = () => {
    const navigate = useNavigate();
    return (
      <div>
        <h2 className="caseHeading">Suggested Reads</h2>
        <Grid container>
            <Grid item lg={12} className="main-case">
                <Grid item lg={4}>
                <div className='case-study' style={{cursor:'pointer'}} onClick={ () => 
                    navigate('/in-case-studies')
                }>
                    <div className="groupCaseStudy1">
                        <img src={Cococola} className="image-case"/>
                        <p className="cocacolaTag">How Coca Cola Earned?</p>
                        <p className="cocacolaData">
                            Coca Cola's UK team collaborated with channel partners through
                            Reechn to reach over 150,000 consumers across London....<span style={{ color: 'rgba(76, 154, 255, 1)' }}>Read More</span>
                        </p>
                    </div>
                </div>
                </Grid>
                <Grid item lg={4}>
                <div className='case-study' style={{cursor:'pointer'}}>
                    <div className="groupCaseStudy1">
                        <img src={Lipton} className="image-case"/>
                        <p className="cocacolaTag">Have a sip of Lipton Tea</p>
                        <p className="cocacolaData">
                        summer sampling activation that helped Lipton to create a buzz for
							Ice Tea by extending out to thousands of people in 3 different
							cities in the UK....<span style={{ color: 'rgba(76, 154, 255, 1)' }}>Read More</span>
                        </p>
                    </div>
                </div>
                </Grid>
                <Grid item lg={4}>
                <div className='case-study' style={{cursor:'pointer'}}>
                    <div className="groupCaseStudy1">
                        <img src={Gin} className="image-case"/>
                        <p className="cocacolaTag">The surreal taste of Gin</p>
                        <p className="cocacolaData">
                        Geezy emphasised the unique feature of Heineken Silver, which is
							the refreshing lager at 4% ABV made using an ice-cold brewing
							process at -1°C....<span style={{ color: 'rgba(76, 154, 255, 1)' }}>Read More</span>
                        </p>
                    </div>
                </div>
                </Grid>
            </Grid>
            <Grid item lg={12} className="case2">
                <p className="view-all">View All</p>
            </Grid>

        </Grid>
      </div>
    )
}

export default SuggestedReads