import { Grid } from "@mui/material";
import React from "react";
import "../../components/styles/sampleair.css";
import Premium from "../../assests/premium.svg";
import Invite from "../../assests/invite-team.svg";
import People from "../../assests/people.png";
import Port from "../../assests/portfolio.svg";
const HotelAir = () =>{
    return(
        <>
        <h1 className="head1"> Why Sample Through Restaurants/Cafe? </h1>
        <Grid container className="sample-cont">
           <Grid item lg={12} className="sample-main">
             <div className="sample-div">
                <img src={Premium} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Cross promotion</p>
             <span className="sample-para1">Restaurants and cafes may have existing partnerships with other brands that can help brands to cross-promote their products.</span>
             </div>
             <div className="sample-div">
             <img src={Invite} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Seasonal experience</p>
             <span className="sample-para1">Works the best for products that are seasonal or tied to a specific occasion. </span>
             </div>
             <div className="sample-div">
             <img src={People} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Cost-effective</p>
             <span className="sample-para1">With the help of ready infrastructure, restaurants and cafes offer ample space to promote products.  </span>
             </div>
             <div className="sample-div">
             <img src={Port} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Up-selling</p>
             <span className="sample-para1">Customers may want to buy the full version of related products. Restaurants can use this to upsell by offering the full-sized product.</span>
             </div>
           </Grid>
        </Grid>
        </>
    )
}
export default HotelAir