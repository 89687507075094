import { Grid } from "@mui/material";
import React from "react";
import "../../components/styles/whats.css";
import F94 from "../../assests/Frame 294.png";
import F93 from "../../assests/Frame 293.png";
import F92 from "../../assests/Frame 292.png";
import F89 from "../../assests/Frame 289.png";
import F90 from "../../assests/Frame 290.png";
import F91 from "../../assests/Frame 291.png";

const WhatsIn = () => {
    return(
        <div style={{height:'800px'}}>
        <h1 className="brands-heading">What’s In There For You?</h1>
        <Grid container className="whats-container">
<Grid item lg={12} style={{display:'flex'}}>
<Grid item lg={4}>
    <img src={F94} alt ="" className="box-1"/>
    <p className="brand-head">Brand Reviews</p>
    <span className="brand-para">Analyse every aspect of your brand to work better for brand management and marketing effectiveness.</span>
</Grid>
<Grid item lg={4}>
<img src={F93} alt ="" className="box-2"/>
<p className="brand-head">Brand Loyality</p>
<span  className="brand-para">Reach consumers at the right time to build a long-term connection with the product. </span>
</Grid>
<Grid item lg={4}>
<img src={F92} alt ="" className="box-3" />
<p className="brand-head">Customer Feedback</p>
<span  className="brand-para">Get important insights, issues, and input shared by customer about their experience with product.</span>
</Grid>
</Grid>
<Grid item lg={12} style={{display:'flex',marginTop:'-194px'}}>
<Grid item lg={4}>
  <div>
    <img src={F89} alt ="" className="box-4"/>
    <p className="brand-head">Higher Sales</p>
    <span className="brand-para">Observe the sales go higher over a period of time after achieving the trust of valued customers.</span>
  </div>
</Grid>
<Grid item lg={4}>
<img src={F90} alt ="" className="box-5"/>
<p className="brand-head">Brand Recognition</p>
<span  className="brand-para">Watch consumers identify your brand and product by its unique attributes over other competitor brands.</span>
</Grid>
<Grid item lg={4}>
<img src={F91} alt ="" className="box-6" />
<p className="brand-head">Brand Awareness</p>
<span  className="brand-para">Watch your brand become a part of the talk among the targeted audience.</span>
</Grid>
</Grid>
</Grid>
        </div>

    )
}
export default WhatsIn