import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Brands from "../../components/BrandsTrust/Brands";
import CaseContent from "./caseContent";
import Suggested from "../../components/Blogs/suggested";

const Blog = () => {
  return (
    <>
      <Header />
      <CaseContent />
      <Suggested type="CaseStudies" />
      <Brands />
      <Footer />
    </>
  );
};

export default Blog;
