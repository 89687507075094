import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cococola from "../../assests/cococola.png";
import Lipton from "../../assests/lipton.png";
import Gin from "../../assests/gin.png";
import "../../components/styles/caseStudies.css";
import { useNavigate } from "react-router-dom";
import { services } from "../../service/ServiceConstants";
import BlogCard from "../BlogCard/blogCard";
import Loader from "../Spinner";

const Suggested = ({ type }) => {
  const navigate = useNavigate();

  const [blogList, setBlogList] = useState();
  //   [
  //   {
  //     body1:
  //       "blogList blogList blogList blogList blogList blogList blogList blogList",
  //     desc: 'blogList blogList blogList blogList blogList blogList blogList blogList"',
  //     title:
  //       'blogList blogList blogList blogList blogList blogList blogList blogList"',
  //     createdAt: "2023-04-03T00:00:00.000Z",
  //     _id: "643e3283b11e",
  //   },
  //   {
  //     body1:
  //       "blogList blogList blogList blogList blogList blogList blogList blogList",
  //     desc: 'blogList blogList blogList blogList blogList blogList blogList blogList"',
  //     title:
  //       'blogList blogList blogList blogList blogList blogList blogList blogList"',
  //     createdAt: "2023-04-03T00:00:00.000Z",
  //     _id: "643e3283b11ee767",
  //   },
  //   {
  //     body1:
  //       "blogList blogList blogList blogList blogList blogList blogList blogList",
  //     desc: 'blogList blogList blogList blogList blogList blogList blogList blogList"',
  //     title:
  //       'blogList blogList blogList blogList blogList blogList blogList blogList"',
  //     createdAt: "2023-04-03T00:00:00.000Z",
  //     _id: "643e",
  //   },
  // ]

  const [filters, setFilters] = useState({
    page: 1,
    query: type === "CaseStudies" ? "CaseStudies" : "Blog",
  });
  const [loading, setLoading] = useState(false);

  const fetchBlogsList = async () => {
    setLoading(true);
    const response = await services.fetchBlogsList(filters);
    if (response?.data?.contents?.length > 0) {
      setBlogList(response?.data?.contents);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogsList();
  }, [filters.page]);

  return (
    <div style={{ padding: "50px" }}>
      <div
        style={{
          marginBottom: "10px",
          marginLeft: "68px",
          letterSpacing: "0.04em",
          fontWeight: "700px",
        }}
        className="caseHeading"
      >
        Suggested Reads
      </div>
      <Grid container>
        <Grid item lg={12} className="main-case">
          {blogList?.length > 0 &&
            blogList
              ?.slice(0, 3)
              ?.map(({ body1, desc, images, title, createdAt, _id }) => {
                return (
                  <Grid
                    item
                    xs={4}
                    lg={4}
                    md={4}
                    style={{ paddingBottom: "40px", marginRight: "10px" }}
                  >
                    <BlogCard
                      body={body1}
                      desc={desc}
                      images={images}
                      title={title}
                      date={createdAt}
                      blogId={_id}
                      suggested={true}
                      CaseStudies={type === "CaseStudies"}
                    />
                  </Grid>
                );
              })}
        </Grid>
        {blogList?.length > 0 && (
          <Grid item lg={12} className="case2">
            <div
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(
                  type === "CaseStudies" ? "/in-case-studies" : "/blogs"
                );
              }}
              className="view-all"
              style={{ marginBottom: "150px", marginTop: "10px" }}
            >
              View All
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Suggested;
