import React, { useState } from "react";
import geezy from "../../assests/geezy x.svg";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../styles/header.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <header className={"main-header"}>
      <nav class="navbar navbar-inverse navbar-fixed-top geezy-header">
        <div
          class="container-fluid header-section"
          style={{
            padding: `10px`,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            class="navbar-header"
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
          >
            <img
              src={geezy}
              alt=""
              style={{ marginTop: "5px", cursor: "pointer" }}
            />
          </div>
          <div className="main-items">
            <ul className="nav-links" style={{ display: "flex" }}>
              <li className="menu-main">
                <Link to="" className="links">
                  <span>Solutions</span>
                  <KeyboardArrowDownIcon
                    className="arrow-icon"
                    onClick={handleClick}
                  />
                </Link>
                <Menu
                  sx={{ left: "-217px" }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    sx: {
                      backgroundColor: "rgba(48, 48, 54, 0.9)",
                      color: "white",
                      fontFamily: "Inter",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/in-short-term-rentals");
                    }}
                    className="items-menu"
                    style={{ fontFamily: "Inter" }}
                  >
                    Short Term Rentals
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/in-food-delivery");
                    }}
                    className="items-menu"
                    style={{ fontFamily: "Inter" }}
                  >
                    Food Delivery
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/in-office-spaces");
                    }}
                    className="items-menu"
                    style={{ fontFamily: "Inter" }}
                  >
                    Office Spaces
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/in-gyms");
                    }}
                    className="items-menu"
                    style={{ fontFamily: "Inter" }}
                  >
                    Fitness Centers
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/in-hotels-restaurants");
                    }}
                    className="items-menu"
                    style={{ fontFamily: "Inter" }}
                  >
                    Restaurants And Cafes
                  </MenuItem>
                </Menu>
              </li>
              <li>
                <Link
                  to="/in-case-studies"
                  className="links"
                  onClick={() => window.scrollTo(0, 0)}
                  style={{ fontFamily: "Inter" }}
                >
                  Case Studies{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="/blogs"
                  className="links"
                  onClick={() => window.scrollTo(0, 0)}
                  style={{ fontFamily: "Inter" }}
                >
                  Articles{" "}
                </Link>
              </li>
              <li>
                <a
                  href="#faq"
                  className="links"
                  style={{ fontFamily: "Inter" }}
                >
                  FAQ's
                </a>
              </li>
              <li>
                {" "}
                <Link
                  to="/in-about-us"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="links"
                  style={{ fontFamily: "Inter" }}
                >
                  About Us
                </Link>
              </li>
            </ul>
          </div>

          <div style={{ marginRight: "127px", marginTop: "4px" }}>
            <button
              className="contactUs-btn"
              onClick={() => {
                window.scrollTo({ top: 10000, behavior: "smooth" });
              }}
            >
              <label className="label-contact">Contact Now</label>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
