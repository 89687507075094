import { Grid } from "@mui/material";
import React from "react";
import "../../components/styles/sampleair.css";
import Premium from "../../assests/premium.svg";
import Invite from "../../assests/invite-team.svg";
import People from "../../assests/people.png";
import Port from "../../assests/portfolio.svg";
const SampleAir = () => {
  return (
    <>
      <h1 className="head1"> Why Sample Through Short Term Rentals? </h1>
      <Grid container  className="sample-cont">
        <Grid item lg={12} className="sample-main">
          <div className="sample-div">
            <img src={Premium} alt="" className="img1-sampl" />
            <p className="sample-head1">Target Audience</p>
            <span className="sample-para1">
            Brands can choose properties based on their location, size, and style, allowing them to reach the consumers they want.{" "}
            </span>
          </div>
          <div className="sample-div">
            <img src={Invite} alt="" className="img1-sampl" />
            <p className="sample-head1">Intimate setting</p>
            <span className="sample-para1">
            Intimate setting for consumers to engage with the product in a relaxed and comfortable environment{" "}
            </span>
          </div>
          <div className="sample-div">
            <img src={People} alt="" className="img1-sampl" />
            <p className="sample-head1">Unique experience</p>
            <span className="sample-para1">
            The unconventional event space like short-term rental properties helps brand stand out from the competitions.
            </span>
          </div>
          <div className="sample-div">
            <img src={Port} alt="" className="img1-sampl" />
            <p className="sample-head1">Social media exposure</p>
            <span className="sample-para1">
            Most consumers who come, tend to share their experience on social media which can help in creating a buzz for the product.
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default SampleAir;
