import React from "react";
import Footer from "../../components/Footer/Footer";
import Brands from "../../components/BrandsTrust/Brands";
import Header from "../../components/Header/Header";
import ShortTermContent from "./ShortTermContent";
import WhatsIn from "./WhatsIn";
import SampleAir from "./SampleAir";
import Gallery from "./Gallery";
import ConsumersInternal from "../../components/Consumers/ConsumersInternal";


const ShortTermRental = () => {
return(
    <>
    <Header />
     <ShortTermContent />
     <WhatsIn />
     <SampleAir />
     <ConsumersInternal />
     <Gallery />
     <Brands />
    <Footer />
    </>
)
}
export default ShortTermRental 