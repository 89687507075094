import React from "react";
import "../../components/styles/gallery.css";
import { Grid } from "@mui/material";
import Gal1 from "../../assests/Gallery/G1.png";
import Gal2 from "../../assests/Gallery/G2.png";
import Gal3 from "../../assests/Gallery/G3.png";
import Gal4 from "../../assests/Gallery/G4.png";

const Gallery = () => {
    return (
        <>
            <h1 className="gal-heading">Gallery</h1>
            <Grid container style={{ padding: ' 0px 67px 0px',marginBottom:'175px' }}>
                <Grid item lg={12} className="gal-12-div">
                    <div className="gal-div">
                        <img src={Gal1} alt="" />
                    </div>
                    <div className="gal-div1">
                    <img src={Gal2} alt="" />
                    </div>
                    <div className="gal-div2">
                    <img src={Gal3} alt="" />
                    </div>
                    <div className="gal-div1">
                    <img src={Gal4} alt="" />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
export default Gallery