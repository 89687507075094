import React from "react";
import "../../components/styles/gallery.css";
import { Grid } from "@mui/material";
import Gal5 from "../../assests/Gallery/G5.png";
import Gal6 from "../../assests/Gallery/G6.png";
import Gal7 from "../../assests/Gallery/G7.png";
import Gal8 from "../../assests/Gallery/G8.png";

const OfficeGallery = () => {
    return (
        <>
            <h1 className="gal-heading">Gallery</h1>
            <Grid container style={{ padding: ' 0px 67px 0px',marginBottom:'125px' }}>
                <Grid item lg={12} className="gal-12-div">
                    <div className="gal-div">
                        <img src={Gal5} alt="" />
                    </div>
                    <div className="gal-div1">
                    <img src={Gal6} alt="" />
                    </div>
                    <div className="gal-div2">
                    <img src={Gal7} alt="" />
                    </div>
                    <div className="gal-div1">
                    <img src={Gal8} alt="" />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
export default OfficeGallery