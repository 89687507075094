import { Box, Grid } from "@mui/material";
import React from "react";
import Lipton from "../../assests/Brands/Frame 239.png";
import Kellogos from "../../assests/Brands/Kelloggs.png";
import Mentos from "../../assests/Brands/Frame 240.png";
import Pg from "../../assests/Brands/Frame 241.png";
import Barista from "../../assests/Brands/Frame 242.png";
import Sprite from "../../assests/Brands/Frame 244.png";
import Danone from "../../assests/Brands/Frame 248.png";
import Zespri from "../../assests/Brands/Frame 243.png";
import Aperol from "../../assests/Brands/Frame 250.png";
import Tropicon from "../../assests/Brands/Frame 247.png";
import Heine from "../../assests/Brands/Frame 246.png";
import Frame249 from "../../assests/Brands/Frame 249.png";
import Cadbuary from "../../assests/Brands/path3298.png";
import Colgate from "../../assests/Brands/Frame 259.png";
import Unilever from "../../assests/Brands/Frame 245.png";
import RedBull from "../../assests/Brands/Red Bull.png";
import Nescafe from "../../assests/Brands/NESCAFÉ svg.png";
import Pepsi from "../../assests/Brands/Pepsi svg.png";
import Cococola from "../../assests/Brands/Frame 236.png";
import Rowdy from "../../assests/Brands/Frame 260.png";
import Dare from "../../assests/Brands/Frame 262.png";
import Royal from "../../assests/Brands/Frame 261.png";
import "../../components/styles/brands.css";
const AboutBrands = () => {

    return (
        <>
            <Grid container className="brand-conatiner-about">
                <h1 className="brands-heading" style={{color:'white'}}>Brands Who Trust Us</h1>
                <Grid item lg={12} style={{ display: 'flex' }}>
                    <img src={Lipton} alt=""
                        style={{
                            borderRadius: '39px',
                            marginRight: '33px',
                            height: '88px',
                            width: '150px',
                            padding: '5px 12px 2px',
                            marginLeft: '5px'
                        }} />
                    <img src={Kellogos} alt=""
                    className="kellogos"
                       />
                    <img src={Mentos} alt=""
                        style={{
                            borderRadius: '39px',
                            marginRight: '33px',
                            height: '88px',
                            width: '150px',
                            padding: '5px 12px 2px'
                        }} />
                    <img src={Pg} alt=""
                        className="pg" />
                    <img src={Barista} alt=""
                        className="barista" />
                    <img src={Sprite} alt=""
                        className="sprite" />
                    <img src={Danone} alt=""
                        className="danone" />
                </Grid>

                <Grid item lg={12} style={{ display: 'flex' }}>

                    <img src={Zespri} alt=""
                        className="zespri" />
                    <img src={Aperol} alt=""
                        className="aperol" />
                    <img src={Tropicon} alt=""
                        className="topricon" />
                    <img src={Heine} alt=""
                        className="heine" />
                    <img src={Frame249} alt=""
                        className="frame249" />
                    <img src={Cadbuary} alt=""
                        className="cadbuary" />
                </Grid>

                <Grid item lg={12} style={{ display: 'flex' }}>
                    <img src={Colgate} alt=""
                        className="colgate" />
                    <img src={Unilever} alt=""
                        className="unilevr" />
                    <img src={RedBull} alt=""
                        className="redbull" />
                    <img src={Nescafe} alt=""
                        className="nescafe" />

                </Grid>
                <Grid item lg={12} style={{ display: 'flex' }}>
                    <img src={Pepsi} alt=""
                        className="pepsi" />
                    <img src={Cococola} alt=""
                        className="coco" />
                    <img src={Rowdy} alt=""
                        className="rowdy" />
                    <img src={Dare} alt=""
                        className="dare" />
                    <img src={Royal} alt=""
                        className="royal" />
                </Grid>

            </Grid>
        </>
    )
}
export default AboutBrands