import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const requestHandler = async (request) => request;

export const successHandler = (response) => response;

export const errorHandler = (error) => {
  if (error.response.status === 401) {
    toast.error(error.response.data.message);
    setTimeout(() => {
      window.location.href = "/";
      sessionStorage.clear();
    }, 1000);
    return;
  }
  // eslint-disable-next-line consistent-return
  return Promise.reject(error);
};
