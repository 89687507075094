import React from "react";
import Header from "../../components/Header/Header";
import FitnessContent from "./FitnessContent";
import WhatsIn from "../ShortTermRentals/WhatsIn";
import Brands from "../../components/BrandsTrust/Brands";
import Footer from "../../components/Footer/Footer";
import FitnessTypes from "./FitnessTypes";
import FitnessAir from "./FitnessAir";
import ConsumersInternal from "../../components/Consumers/ConsumersInternal";
import FitnessGallery from "./FitnessGallery";

const FitnessCenterMain = () => {
  return (
    <>
      <Header />
      <FitnessContent />
      <WhatsIn />
      {/* <FitnessTypes /> */}
      <FitnessAir />
      <ConsumersInternal />
      <FitnessGallery />
      <Brands />
      <Footer />
    </>
  );
};
export default FitnessCenterMain;
