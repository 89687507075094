import { Grid } from "@mui/material";
import React from "react";
import "../../components/styles/sampleair.css";
import Premium from "../../assests/premium.svg";
import Invite from "../../assests/invite-team.svg";
import People from "../../assests/people.png";
import Port from "../../assests/portfolio.svg";
const FitnessAir = () =>{
    return(
        <>
        <h1 className="head1"> Why Sample Through Fitness Centers? </h1>
        <Grid container className="sample-cont">
           <Grid item lg={12} className="sample-main">
             <div className="sample-div">
                <img src={Premium} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Target Audience</p>
             <span className="sample-para1">Fitness centres provide access to targeted audience of health-conscious people who are open to trying new products related to health and wellness</span>
             </div>
             <div className="sample-div">
             <img src={Invite} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Relevance</p>
             <span className="sample-para1">An ideal space to promote health and well-being products, as consumers are more receptive to these types of products and might end up purchasing them. </span>
             </div>
             <div className="sample-div">
             <img src={People} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Word-of-mouth</p>
             <span className="sample-para1">Consumers who enjoy the sample may recommend their friends and family to try it increasing product’s awareness.</span>
             </div>
             <div className="sample-div">
             <img src={Port} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Product feedback</p>
             <span className="sample-para1">Observe how customers interact with products and improve the product’s effectiveness and appeal to target audience.</span>
             </div>
           </Grid>
        </Grid>
        </>
    )
}
export default FitnessAir