/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Button, Grid, Typography } from "@mui/material";
import TextArea from "@atlaskit/textarea";
import { ErrorMessage } from "@atlaskit/form";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { COLORS } from "../Constant/Colors";
import { postReachout } from "../../apis/Home";
import Loader from "../../components/Spinner/index";
import "../styles/reachoutform.css";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ReachUs = () => {
  const [select, setSelect] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const phoneCodeDigit = {
    AU: '+61',
    GB: '+44',
    IN: '+91',
    NZ: '+64',
    NL: '+31',
    US: '+1',
    PT: '+351',
    CA: '+1',
    FR: '+33',
    BE: '+32',
    DE: '+49',
    ES: '+34',
    SG: '+65',
    AE: '+971',
  };

  const [countryKeyCode, setCountryKeyCode] = useState("US");
  const formData = [
    {
      placeHolder: " Your Name",
      field: "name",
      type: "text",
      size: 5.5,
      left: "38px",
    },
    {
      placeHolder: " You are representing...",
      field: "representing",
      type: "select",
      size: 5.5,
    },
    {
      placeHolder: " Email Address",
      field: "email",
      type: "text",
      size: 5.5,
      left: "38px",
    },
    {
      placeHolder: " Website URL(Optional)",
      field: "webUrl",
      type: "text",
      size: 5.5,
    },
    {
      placeHolder: " Business Name",
      field: "business",
      type: "text",
      size: 5.5,
      left: "38px",
    },
    {
      placeHolder: " Mobile Number",
      field: "mobile",
      type: "mobile",
      size: 5.5,
    },
    {
      placeHolder: " How can we help you?(Optional)",
      field: "optional",
      type: "textArea",
      size: 10,
      left: "38px",
    },
  ];
  const validation = Yup.object({
    name: Yup.string().required("Name is Required"),
    representing: Yup.object().required("Representing is Required"),
    business: Yup.string().required("Bussiness is Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    webUrl: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Invalid Web Url!"
    ),
    mobile: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Mobile Number is Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      representing: "",
      email: "",
      webUrl: "",
      business: "",
      mobile: "",
      optional: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      const payload = values;

      for(let code in phoneCodeDigit ) {
        if(countryKeyCode === code) {
          payload.mobile = phoneCodeDigit[code]+'-'+values.mobile;
        }
      }

      (async () => {
        const response = await postReachout(payload);
        if (response?.status === 200) {
          setMessage("Thanks for your interest, we’ll reach out to you soon!");
          setLoading(false);
          formik.resetForm();
          setTimeout(() => {
            setMessage("");
          }, 10000);
        }
        return "";
      })();
    },
    validationSchema: validation,
  });

  const colourStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#8638E5" : null,
        color: isFocused ? "#F1F2F6" : "#494949",
        fontFamily: "Amarnath",
        fontStyle: "normal",
        cursor: "pointer",
      };
    },
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "8px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: COLORS.White,
      },
      "::-webkit-scrollbar-thumb": {
        background: COLORS.lightPurple,
        borderRadius: "30px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: COLORS.lightPurple,
      },
    }),

    control: (styles) => ({
      ...styles,
      backgroundColor: COLORS.lighterGrey,

      width: "244px",
      height: "45px",
      color: "#9E9E9E",
      fontFamily: "Amarnath",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",

      background: "rgba(40, 44, 53, 0.05)",
      borderRadius: "8px",
    }),
  };

  const representList = [
    {
      label: "Brand",
      value: "brand",
    },
    {
      label: "Agency",
      value: "agency",
    },
    {
      label: "Channel Partner",
      value: "channel partner",
    },
  ];
  const onSelect = (code) => {
    setSelect(code);
    setCountryKeyCode(code);
  };

  return (
    <div
      id="#contactNow"
      style={{
        boxShadow: "0px 4px 12px rgba(0, 100, 244, 0.1)",
        borderRadius: "30px",
        height: "584px",
        backgroundColor: "rgba(0, 100, 244, 0.01)",
      }}
    >
      {message ? (
        <div
          style={{
            // width: "0px",
            textAlign: "center",
            position: "relative",
            top: "250px",
            fontFamily: "Amaranth",
            fontSize: "28px",
            fontWeight: 400,
            lineHeight: "34px",
            letterSpacing: "0em",
            textAlign: "center",
          }}
        >
          {message}
        </div>
      ) : (
        <Grid sx={{width:'588px'}}>
          <Typography
            style={{
              // width: "418px",
              height: "29px",
              marginLeft: "38px",
              paddingTop: "23px",
              marginBottom: "20px",
              fontFamily: "Amaranth",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "32px",
              lineHeight: "29px",
              color: "#303036",
            }}
          >
            For more info, reach out to us:
          </Typography>
          <Grid container spacing={4}>
            {formData?.map(({ placeHolder, field, size, type,left }) => (
              <Grid item xs={size} sx={{marginLeft:left}}>
                {type === "textArea" ? (
                  <>
                    <TextArea
                      style={{
                        background: "rgba(0, 100, 244, 0.01)",
                        border: "1px solid rgba(48, 48, 54, 0.2)",
                        borderRadius: "8px",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "21px",
                        letterSpacing: "0.04em",
                        minWidth: "528px",
                        minHeight: "96px",
                        color: "#9E9E9E",
                      }}
                      resize="auto"
                      maxHeight="10vh"
                      placeholder={placeHolder}
                      id="1"
                      size="small"
                      name={field}
                      value={formik.values[field]}
                      onChange={formik.handleChange}
                      // error={errors.channelPartnerSpaceDescription}
                      onKeyDown={(e) => e.key === 13 && e.preventDefault()}
                    />
                    {formik?.errors[field] && formik.touched[field] ? (
                      <ErrorMessage>{formik?.errors[field]}</ErrorMessage>
                    ) : null}
                  </>
                ) : type === "select" ? (
                  <>
                    <Select
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={formik.values[field]}
                      onChange={(e) => {
                        formik.setFieldValue([field], e);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                      name={field}
                      styles={colourStyles}
                      options={representList}
                      placeholder={placeHolder}
                    />
                    {formik?.errors[field] && formik.touched[field] ? (
                      <ErrorMessage>{formik?.errors[field]}</ErrorMessage>
                    ) : null}
                  </>
                ) : type === "mobile" ? (
                  <>
                    <Grid container>
                      <Grid>
                        <ReactFlagsSelect
                         optionsSize={14}
                         className="menu-flags"
                          selected={select.length > 0 ? select : countryKeyCode}
                          onSelect={onSelect}
                          countries={[
                            "AU",
                            "GB",
                            "IN",
                            "NZ",
                            "NL",
                            "US",
                            "PT",
                            "ES",
                            "AE",
                            "SG",
                            "DE",
                            "BE",
                            "FR",
                            "CA",
                          ]}
                          customLabels={{
                            AU: { secondary: "+61" },
                            GB: { secondary: "+44" },
                            IN: { secondary: "+91" },
                            NZ: { secondary: "+64" },
                            NL: { secondary: "+31" },
                            US: { secondary: "+1" },
                            PT: { secondary: "+351" },
                            ES: { secondary: "+34" },
                            AE: { secondary: "+971" },
                            SG: { secondary: "+65" },
                            DE: { secondary: "+49" },
                            BE: { secondary: "+32" },
                            FR: { secondary: "+33" },
                            CA: { secondary: "+1" },
                          }}
                        />
                      </Grid>

                      <Grid style={{ marginLeft: "10px" }}>
                        <input
                          style={{
                            width: "130px",
                            color: "#9E9E9E",
                            height: "37px",

                            background: "rgba(0, 100, 244, 0.01)",
                            border: "1px solid rgba(48, 48, 54, 0.2)",
                            borderRadius: "8px",
                          }}
                          placeholder=" 67XXXXXXX90"
                          name="mobile"
                          value={formik.values[field]}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                    </Grid>
                    {formik?.errors[field] && formik.touched[field] ? (
                      <ErrorMessage>{formik?.errors[field]}</ErrorMessage>
                    ) : null}
                  </>
                ) : (
                  <>
                    <input
                      style={{
                        background: "rgba(0, 100, 244, 0.01)",
                        borderRadius: "8px",
                        border: "1px solid rgba(48, 48, 54, 0.2)",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "14px",
                        width: "239px",
                        height: "44.98px",
                      }}
                      fullWidth
                      placeholder={placeHolder}
                      id={field}
                      size="small"
                      name={field}
                      value={formik.values[field]}
                      onChange={formik.handleChange}
                      type="text"
                    />
                    {formik?.errors[field] && formik.touched[field] ? (
                      <ErrorMessage>{formik?.errors[field]}</ErrorMessage>
                    ) : null}
                  </>
                )}
              </Grid>
            ))}
            {loading ? (
              <Loader relative="relative" />
            ) : (
              <Button
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "11px 8px 13px 11px",
                  gap: "10px",
                  marginBottom: "21px",
                  width: "165px",
                  height: "35px",
                  left: "275px",
                  top: "51px",
                  color: "white",
                  borderRadius: "6px",
                  backgroundColor: "#0064F4",
                  ":hover": {
                    backgroundColor: "#0064F4",
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                <Typography
                  style={{
                    width: "63px",
                    height: "20px",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "20px",
                    alignItems: "center",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Submit
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};
export default ReachUs;
