import React from "react";
import Header from "../../components/Header/Header";
import WhatsIn from "../ShortTermRentals/WhatsIn";
import Brands from "../../components/BrandsTrust/Brands";
import Footer from "../../components/Footer/Footer";
import OfficeSpaceContent from "./OfficeSpaceContent";
import OfficeAir from "./OfficeAir";
import ConsumersInternal from "../../components/Consumers/ConsumersInternal";
import OfficeGallery from "./OfficeGallery";

const OfficeSpaceMain = () => {
    return(
        <>
        <Header />
        <OfficeSpaceContent />
        <WhatsIn />
        <OfficeAir />
            <ConsumersInternal />
           <OfficeGallery />
            <Brands />
            <Footer />
        </>
    )
}
export default OfficeSpaceMain