import { Grid } from "@mui/material";
import React from "react";
import "../../components/styles/sampleair.css";
import Premium from "../../assests/premium.svg";
import Invite from "../../assests/invite-team.svg";
import People from "../../assests/people.png";
import Port from "../../assests/portfolio.svg";
const FoodDeliveryAir = () =>{
    return(
        <>
        <h1 className="head1"> Why Sample Through Food Delivery? </h1>
        <Grid container className="sample-cont">
           <Grid item lg={12} className="sample-main">
             <div className="sample-div">
                <img src={Premium} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Target Audience</p>
             <span className="sample-para1">Offer brands the chance to reach target audience who are already interested in food are likely to be receptive to trying new products. </span>
             </div>
             <div className="sample-div">
             <img src={Invite} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Convenience</p>
             <span className="sample-para1">With food delivery, brands can offer consumers the convenience of trying their products from the comfort of their homes.</span>
             </div>
             <div className="sample-div">
             <img src={People} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Cost-effective</p>
             <span className="sample-para1">With food delivery, brands can reach audiences w/o the overhead costs associated with traditional in-person sampling events.</span>
             </div>
             <div className="sample-div">
             <img src={Port} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Measurable results</p>
             <span className="sample-para1">Sampling through food delivery gives the liberty to track the success of their sampling campaign.</span>
             </div>
           </Grid>
        </Grid>
        </>
    )
}
export default FoodDeliveryAir