import { Box, Grid } from "@mui/material";
import React from "react";
import "../styles/faqs.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReachUs from "../ReachUS/ReachUs";


const Faq = () => {
    return (
        <div id="faq" style={{marginTop: '110px',}}>
            <h1 className="faqs-heading">FAQ'S</h1>

            <Grid container spacing={2}>
                <Grid item lg={6}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p style={{color:'#44949',fontFamily: 'Inter',fontStyle: 'normal',
fontWeight: 600,
fontSize: '21px',
lineHeight: '25px'}}><b>What is Geezy X?</b></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <span style={{color: '#494949'}}>
                            Geezy X helps brands and agencies reach their target consumers and deliver their campaigns successfully.
                            </span>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p style={{color:'#44949',fontFamily: 'Inter',fontStyle: 'normal',
fontWeight: 600,
fontSize: '21px',
lineHeight: '25px'}}><b>How does Geezy X help?</b></p>
                        </AccordionSummary>
                        <AccordionDetails>
                               <span style={{color: '#494949'}}>
                               Geezy X has an exhaustive list of media owners across different categories such as Hotels, Restaurants, Delivery, Rideshare, Salons etc. that gives the liberty to drive the campaign for the accurate target audience.
                               </span>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p style={{color:'#44949',fontFamily: 'Inter',fontStyle: 'normal',
fontWeight: 600,
fontSize: '21px',
lineHeight: '25px'}}><b>How many countries are we present in?</b></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <span style={{color: '#494949'}}>
                                We are present in 15+ countries that include the UK, Australia, New Zealand, France, Belgium, the USA, and Germany.
                            </span>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p style={{color:'#44949',fontFamily: 'Inter',fontStyle: 'normal',
fontWeight: 600,
fontSize: '21px',
lineHeight: '25px'}}><b>How can I connect with Geezy X?</b></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <span style={{color: '#494949'}}>
                                Fill up the form with your correct information and our support group will reach out to you. You can also write to us at support@geezy.co
                            </span>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item lg={6} style={{border: '1px solid floralwhite'}}>
                   <ReachUs />
                </Grid>
            </Grid>

        </div>
    )
}

export default Faq