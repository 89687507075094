import { Box, Grid } from "@mui/material";
import React from "react";
import geezy from "../../assests/geezy x.svg";
import "../styles/footer.css";
import vector from "../../assests/fb.png";
import Faq from "../FAQ's/Faq";
import Insta from "../../assests/instagram-01.png";
import Linked from "../../assests/Frame 248.png";
import { Link, useNavigate } from "react-router-dom";

const PrivacyFooter = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="groupFooter1">
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="groupFooter2">
                <div className="geezyLogo">
                  <img
                    src={geezy}
                    onClick={() => {
                      navigate("/");
                      window.scrollTo(0, 0);
                    }}
                    alt=""
                  />
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item lg={4}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="groupFooter3">
                <Link
                  to="/"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="groupSolutions"
                >
                  Solutions
                </Link>
                <br />
                <Link
                  to="/in-short-term-rentals"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="groupRentals"
                >
                  Short Term Rentals
                </Link>
                <br />
                <Link
                  to="/in-food-delivery"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="groupFood"
                >
                  Food Delivery
                </Link>
                <br />
                <Link
                  to="/in-office-spaces"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="groupOffices"
                >
                  Office Spaces
                </Link>
                <br />
                <Link
                  to="/in-gyms"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="groupFitness"
                >
                  Fitness Centers
                </Link>
                <br />
                <Link
                  to="/in-hotels-restaurants"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="groupHotel"
                >
                  Restaurants And Cafes
                </Link>
                <br />
              </div>
              <div className="groupFooter4">
                <Link className="groupResources">Resources</Link>
                <br />
                <Link
                  to="/in-about-Us"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="groupAbout"
                >
                  About Us
                </Link>
                <br />
                <Link
                  to="/in-case-studies"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="groupCase"
                >
                  Case Studies
                </Link>
                <br />
                <Link
                  to="/blogs"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="groupBlogs"
                >
                  Blogs
                </Link>

                <br />
                <Link to="/"  onClick={() => {
                      navigate("/");
                      setTimeout(()=>{
                        window.scrollTo(0,5600);
                      }, 100)
                    }}  
                    className="groupFAQ">
                  FAQ’s
                </Link>
                <br />
              </div>
              <div className="groupFooter5">
                <Link className="groupLegal">Legal</Link>
                <br />
                <Link
                  to="/in-privacy-policy"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  className="groupPrivacy"
                >
                  Privacy Policy
                </Link>
                <br />
              </div>
            </Box>
          </Grid>
          <Grid item lg={4}>
            <Box>
              <div className="groupFooter6">
                <div class="socialFacebook">
                  <a
                    href="https://www.facebook.com/profile.php?id=100091962520311"
                    target="_blank"
                    style={{ padding: "0px 3px 0px" }}
                  >
                    <span>
                      <img src={vector} className="img1" />
                    </span>
                  </a>
                  <a
                    href="https://www.instagram.com/geezy.exchange/"
                    target="_blank"
                    style={{ padding: "0px 3px 0px" }}
                  >
                    <span>
                      <img src={Insta} className="img2" />
                    </span>
                  </a>
                  <a
                    href="https://www.linkedin.com/showcase/geezy-exchange/"
                    target="_blank"
                    style={{ padding: "0px 3px 0px" }}
                  >
                    <span>
                      <img src={Linked} className="img3" />
                    </span>
                  </a>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item lg={12} p-0>
            <Box className="footerDivider">
              <div className="footer-version">© 2023 Geezy X</div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PrivacyFooter;
