import { Grid, Typography } from "@mui/material";
import moment from "moment";
import Cocacola from "../../assests/cococola.png";
import { DateAndTime, CardDescription } from "../Typographies";
import { createSearchParams, useNavigate, useLocation } from "react-router-dom";

const BlogCard = ({
  body1,
  desc,
  images,
  title,
  createdAt,
  blogId,
  suggested,
  CaseStudies,
  date,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToView = (id) => {
    window.scrollTo(0, 0);
    // window.scrollTo({ top: 0, behavior: "smooth" });
    navigate({
      pathname: CaseStudies ? "/case-study" : "/blog",
      search: createSearchParams({
        id: id,
      }).toString(),
    });
  };

  return (
    <Grid item lg={4} style={{ marginRight: "10px" }}>
      <div
        className="case-study"
        style={{ cursor: "pointer" }}
        onClick={() => navigateToView(blogId)}
      >
        <div
          className="groupCaseStudy1"
          style={{
            width: "416px",
          }}
        >
          <img src={images?.length && images[0]} className="card-img" />
          <Grid className="cocacolaTag">{title}</Grid>
          <Grid>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "30px",
                letterSpacing: "0.04em",
                color: "#282c35",
                marginLeft: "12px",
              }}
            >
              {moment(date).format("DD/MM/YYYY")}
              {/* {", "}
              {moment(createdAt).format("HH:MM A")} */}
            </Typography>
          </Grid>
          <Grid>
            <CardDescription className="cocacolaData">
              {desc?.slice(0, 80)}...
              <span
                style={{
                  color: "rgba(76, 154, 255, 1)",
                  textDecoration: "underline",
                }}
              >
                Read More
              </span>
            </CardDescription>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default BlogCard;
