import { Grid } from "@mui/material";
import React from "react";
import "../../components/styles/sampleair.css";
import Premium from "../../assests/premium.svg";
import Invite from "../../assests/invite-team.svg";
import People from "../../assests/people.png";
import Port from "../../assests/portfolio.svg";
const OfficeAir = () =>{
    return(
        <>
        <h1 className="head1"> Why Sample Through Offices/Co-working </h1>
        <Grid container className="sample-cont">
           <Grid item lg={12} className="sample-main">
             <div className="sample-div">
                <img src={Premium} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Cost-effective</p>
             <span className="sample-para1">The co-working spaces are affordable, flexible, and include several amenities, including high-speed internet, shared kitchen spaces, and meeting rooms. </span>
             </div>
             <div className="sample-div">
             <img src={Invite} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Feedback</p>
             <span className="sample-para1">This space is a great place to gather product review and feedback. </span>
             </div>
             <div className="sample-div">
             <img src={People} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Brand awareness</p>
             <span className="sample-para1">Co-working spaces often are bustling with crowds who are open to try out new things. This helps in spreading the word about your product.</span>
             </div>
             <div className="sample-div">
             <img src={Port} alt ="" className="img1-sampl"/>
             <p className="sample-head1">Target audience</p>
             <span className="sample-para1">Co-working spaces have diversity that acts as a good opportunity to promote brands and products</span>
             </div>
           </Grid>
        </Grid>
        </>
    )
}
export default OfficeAir