import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Content from "../../components/Content/Content";
// import AgencyPartner from "../../components/AgencyPartners/AgencyPartner";
import Brands from "../../components/BrandsTrust/Brands";
import CaseArticles from "../../components/CaseStudies/CaseArticles";
import GlobeMap from "../../components/Globe/GlobeMap";
import Consumers from "../../components/Consumers/Consumers";
import Product from "../../components/Products/Product";
import Service from "../../components/Services/Service";
import Campaign from "../../components/Campaigns/Campaign";
import SampleChannel from "../../components/SamplingChannels/SampleChannel";
import Article from "../../components/Articles/Article";
import Suggested from "../../components/Blogs/suggested";

const LandingPage = () => {
  return (
    <>
      <Header />
      <Content />
      <SampleChannel />
      <Service />
      <Campaign />
      <Consumers />
      <GlobeMap />
      <CaseArticles CaseStudies="CaseStudies" />
      <Article />
      <Brands />
      {/* <AgencyPartner /> */}
      <Footer />
    </>
  );
};

export default LandingPage;
