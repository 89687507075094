import { axiosInstance as API } from "./AxiosInstance";

export const services = {
  postReachoutData: (data, token) =>
    API.post("/post/form", data, {
      headers: {
        "Content-Type": "application/json",
      },
    }),

  fetchBlogsList: (filters) =>
    API.get("/getAll/contents", {
      // headers: { 'x-auth-token': token },
      params: {
        page: filters?.page,
        type: filters?.query,
      },
    }),

  searchBlogByBlogId: (id) =>
    API.get(`/get/content/byId/${id}`, {
      // headers: { "x-auth-token": token },
    }),
};
