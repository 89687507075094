import { Grid } from "@mui/material";
import React from "react";
import "../styles/consumer.css";

const Consumers = () => {
  return (
    <>
      <Grid container className="consumer-main">
        <Grid item lg={12} className="consumer-items">
          <div>
            <span className="para-1">20M+</span>
            <p className="span-1">Samples</p>
          </div>
          <div>
            <span className="para-1">400+</span>
            <p className="span-1">Brands</p>
          </div>
          <div>
            <span className="para-1">1000+</span>
            <p className="span-1">Campaigns</p>
          </div>
        </Grid>
      </Grid>
      <Grid item lg={12} className="consumer-content-box">
        <div className="consumer-content">
          <div style={{ marginBottom: "45px" }}>
            <p style={{ margin: " 12px 39px 11px;" }}>
              Putting brands in front of selected{" "}
            </p>
            <p style={{ margin: "13px", lineHeight: "0px" }}>
              audiences, at moments that count
            </p>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default Consumers;
