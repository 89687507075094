import { services } from "../service/ServiceConstants";

export const postReachout = async (filter) => {
  try {
    const passValues = {
      name: filter?.name,
      email: filter?.email,
      phone: filter?.mobile,
      businessName: filter?.business,
      websiteUrl: filter?.webUrl,
      query: filter?.optional,
      representingAs: filter?.representing?.value,
      type: "GeezyX",
    };
    const response = await services.postReachoutData(passValues);
    return response;
  } catch (err) {
    return err;
  }
};
